import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '@env/environment';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { DetailMapModalComponent } from '@app/components/modals/detail-map-modal/detail-map-modal.component';
import { getDemandStatusLabel, getStatusLabel } from '@app/shared/helpers/demand-helper';
import * as _ from 'lodash';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface advancedMarkers {
  position: google.maps.LatLngLiteral,
  option: google.maps.marker.AdvancedMarkerElementOptions,
  demand: any
}

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnInit {
  DemandStateCode = DemandStateCode;
  getDemandStatusLabel = getDemandStatusLabel;
  getStatusLabel = getStatusLabel;
  statusMarkerColors = environment.status_colors;

  // cartographie
  zoom: number = environment.map_zoom;
  center: google.maps.LatLngLiteral = { lat: environment.map_latitude, lng: environment.map_longitude }
  POI: Boolean = environment.map_POI;
  styleOff: google.maps.MapTypeStyle[] = [{ featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }]}, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }]}];
  mapOptions: google.maps.MapOptions =  {
    fullscreenControl: false,
    streetViewControl: false,
    styles: this.styleOff}
  // Cluster
  clusterIcon = "/assets/map_cluster";
  // Marker
  advancedMarkers: advancedMarkers[] = [];

  constructor(
    public dialogRef: MatDialogRef<GoogleMapsComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) {
    this.addAdvancedMarkers();
  }

  ngOnInit() {
  }

  // Quand la carte est ready
  public onMapReady(map : google.maps.Map): void {
    map["style"] = this.styleOff;
  }

  // Créer les markers
  private addAdvancedMarkers() {
    for(var demand of this.inputData.demands) {
      try {
        this.advancedMarkers.push({
          position: { lat: parseFloat(demand.location_latitude), lng: parseFloat(demand.location_longitude) },
          option: { 
            gmpDraggable: false,
          },
          demand: demand
        })
      } catch (error) {
        console.error("error on demand : " + demand.id)
      }
    }
  }

  // Permet d'obtenir l'icon d'un marker
  public getSVGIcon(marker: advancedMarkers) {
    let color = "#FF0000";
    if (environment.toggle_feature_map_by_status) {
      color = this.statusMarkerColors[marker.demand.workflow_current_state_code];
    }
    let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="${color}" >
        <g style="stroke: black; stroke-width: 1px;">
          <path d="M12 2C7.03 2 3 6.03 3 11c0 6 9 13 9 13s9-7 9-13c0-4.97-4.03-9-9-9zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z"/>
        </g>
    </svg>`;
    let encodedSVG = encodeURIComponent(svgContent);
    return `data:image/svg+xml;charset=utf-8,${encodedSVG}`;
  }

  // ferme la popup d'un marker
  public onClose(): void {
    this.dialogRef.close();
  }

  // Permet d'ouvrir la popup d'un marker
  public onClickStatus(marker : advancedMarkers) {
    this.dialog.open(DetailMapModalComponent, {
      data: {demand: marker.demand},
      autoFocus: false,
    });
  }

  // Retourne le texte en majuscule
  public capitalize(text: string): string {
    return _.capitalize(text);
  }
}
