import { Injectable, NgZone } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import {environment} from "@env/environment";
import { DemandService } from '@app/shared/services/demand/demand.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private zone: NgZone,
    private authSrv: AuthenticationService,
    private demandService: DemandService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = localStorage.getItem('token');
    const authentication_types = localStorage.getItem('authentication_types');

    /*request = request.clone({
      setHeaders: {
        'Authorization': 'Token 88a0b4cd0602171c75e5eaa62acae41b3ee3873c',
        'Authorization-x': 'false',
        'Authorization-type': 'keycloak_internal',
      },
    });*/

    if (token) {
      if (this.authSrv.isGoogleToken(token) && !this.authSrv.handleExpiredToken(token)) {
        this.zone.run(() => {
          // this.router.navigate(['/login']);
        });
      } else {
        let tokenName = 'Token';
        if (environment.client_name === 'sabom') {
          if (this.authSrv.isGoogleToken(token)) { tokenName = 'Bearer'; }
        }

        request = request.clone({
          setHeaders: {
            'Authorization': `${tokenName} ${token}`,
            'Authorization-x': `${environment.production}`,
            'Authorization-type': `${authentication_types}`,
          },
        });
      }
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 403) {
          this.demandService.saveStartPageUrl();
          this.router.navigate(['not-authorized'], { queryParams: { email: err.error.email }});
          this.zone.run(() => {
            this.router.navigate(['/login']);
          });
        }

        const error = err.error.error || err.status;
        return throwError(error);
      }),
    );
  }
}
