<div mat-dialog-title id="map-header">
  <div class="legend">
    <div class="legend-left">
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.ENVOYEE]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.ENVOYEE)) }}</span>
      </div>
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.AFFECTEE]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.AFFECTEE)) }}</span>
      </div>
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.A_COMPLETER]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.A_COMPLETER)) }}</span>
      </div>
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.EN_COURS]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.EN_COURS)) }}</span>
      </div>
       <div class="legend-item">
         <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.SUSPENDUE]"></div>
         <span>{{ capitalize(getStatusLabel(DemandStateCode.SUSPENDUE)) }}</span>
       </div>
       <div class="legend-item">
         <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.REJETEE]"></div>
         <span>{{ capitalize(getStatusLabel(DemandStateCode.REJETEE)) }}</span>
       </div>
       <div class="legend-item">
         <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.EN_ATTENTE_ABANDON]">
         </div>
         <span>{{ capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON)) }}</span>
       </div>
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.EN_ATTENTE_CLOTURE]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE)) }}</span>
      </div>
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.CLOTUREE]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.CLOTUREE)) }}</span>
      </div>
      <div class="legend-item">
        <div class="status-box" [style.background-color]="statusMarkerColors[DemandStateCode.ABANDONNEE]"></div>
        <span>{{ capitalize(getStatusLabel(DemandStateCode.ABANDONNEE)) }}</span>
      </div>
      <div class="legend-item">
        <img src="assets/map_cluster-blue.svg"/>
        <span> <10</span>
      </div>
      <div class="legend-item">
        <img src="assets/map_cluster-yellow.svg" />
        <span> 10-100</span>
      </div>
      <div class="legend-item">
        <img src="assets/map_cluster-red.svg" />
        <span> >100</span>
      </div>
    </div>
    <div class="legend-right">
        <button mat-icon-button
            matTooltip="Fermer"
            aria-label="Fermer la carte"
            class="clickable"
            (click)="onClose()"
          >
          <mat-icon class="clear-icon">clear</mat-icon>
        </button>
    </div>
  </div>
  <div class="close-mobile">
    <mat-icon matTooltip="Fermer" class="clickable" (click)="onClose()">clear</mat-icon>
  </div>
</div>
<mat-dialog-content>
  <div>
    <google-map class="map"
    (mapInitialized)="onMapReady($event)"
    height="100%"
    width="auto"
    [center]="center"
    [zoom]="zoom"
    [options]="mapOptions">
      <map-marker-clusterer [(imagePath)]="clusterIcon" maxZoom="13">
        @for (marker of advancedMarkers; track $index) {
          <map-marker [position]="marker.position" [icon]="getSVGIcon(marker)" (mapClick)="onClickStatus(marker)" [clickable]="true"/>
        }
      </map-marker-clusterer>
    </google-map>
  </div>
</mat-dialog-content>
