import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef, Component,
  EventEmitter,
  Input,
  OnInit,
  AfterViewInit,
  Output, SimpleChanges, ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getUserHome,
  getUserMode,
  userIsDemandeur,
  userIsExploitant,
} from '@app/shared/helpers/user-modes-helper';
import { Demand, DemandObject } from '@app/shared/models/demand';
import { Value } from '@app/shared/models/value';
import { Zone } from '@app/shared/services/contracts.service';
import { DelaiService } from '@app/shared/services/delai.service';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { HoraireService } from '@app/shared/services/horaire.service';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, debounceTime } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MapGeocoder } from '@angular/google-maps';

declare var google: any;

@Component({
  selector: 'app-demand-form',
  templateUrl: './demand-form.component.html',
  styleUrls: ['./demand-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DemandFormComponent implements OnInit, AfterViewInit {
  @Input() user;
  @Input() set demand(value: Demand | undefined) {
    if (value === undefined) {
      value = new Demand();
      value.is_creator_beneficiary = true;
      this.editionMode = false;
      this.demandService.changeEditMode(false);
      this.lazyLoading = false;
    }

    this._demand = value;
  }
  public min = new Date(new Date().setDate(new Date().getDate() + 1));
  @Input() set beneficiaryTypes(val) {
    this._beneficiaryTypes = val;
    if (this.client_name === 'national') { this.initBenificiaryTypeNational(); }
    if (this.client_name === 'sabom') { this.initBenificiaryTypeSabom(); }
  }
  @Input() emplacementTypes: Value[];
  @Input()
  set activityTypes(_activityTypes: Value[]) {
    this._activityTypes = _activityTypes;
    if (this.client_name === 'sabom') { this.initConcernedActivitySabom(); }
    if (this.client_name === 'setom') { this.initConcernedActivitySetom(); }
  }
  @Input()
  set objectTypes(_value: Value[]) {
    this.objectTypeWithImpactWithoutObject = _value.filter((x) => {
      if (x.impact_without_object) return x
    }).map((x) => x.id)
    this._objectTypes = _value;
    this.manageObjectTypes();
  }
  @Input()
  set impactTypes(_value: Value[]) {
    this._impactTypes = _value;
    setTimeout(() => {
      this.manageImpactTypes();
    }, 1000);
  }
  @Input() communication_channel: string;

  @Input()
  set ouvrageTypes(_value: Value[]) {
    this._ouvrageTypes = _value;
    this.manageOuvrageTypes();
  }

  @Input()
  set userContracts(_userContracts: any[]) {
    this._userContracts = _userContracts;
    this.availableContracts = this._userContracts.filter((contract) => contract.visible);

    // In creation, if the user has only 1 contract, initialize the select with it
    if (
      this.newDemand &&
      !this.isDuplicate &&
      this._userContracts &&
      this._userContracts.length === 1 &&
      this.demandGroup
    ) {
      this.demandGroup.controls['contract'].setValue(
        this._userContracts[0].code,
      );
    }
    if (this.isDuplicate && this._userContracts && this.demandGroup && !this.availableContracts.find((o) => o.code === this._demand.contract)) {
      this.demandGroup.get('contract').reset();
    }

    if (this.client_name === 'sabom') { this.initBenificiaryTypeSabom(); }
    if (this.client_name === 'sabom') { this.initConcernedActivitySabom(); }

    // Get 'POLE' contracts shapes
    if (this.displayContractShape) {
      for (const contract of this._userContracts.filter((c) => c.type === 'POLE')) {
        contract.code == 'PX' ? contract.color = '#050505' : contract.color = '#0062a9';
        this.contracts.push(contract);
      }
      this.getPolylineVertices();
    }

    //preselect with contract from url
    if  (this.contract_from_url) {
      if (this._userContracts.find((x) => x.code === this.contract_from_url.toUpperCase())) {
        this.demandGroup.controls['contract'].setValue(this.contract_from_url);
        this.contract_from_url = null;
      }
    }
  }

  @Input()
  set contractZones(_contractZones: Zone[]) {
    this._contractZones = _contractZones;
  }

  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectContract: EventEmitter<any> = new EventEmitter<any>();
  @Input() events: Observable<void>;

  private lazyLoading : boolean = true;
  public now: string;

  public displayContractShape: boolean = environment.toggle_feature_map_contract_shape_display;
  public contracts: number[][] = [];

  public isbtnSaveClicked = false;
  public isTryingToSendAndSave = false;
  public _demand: any;
  public mode: string;
  public contract_from_url: string;
  public demandGroup: FormGroup;
  public saveBtnClicked = false;
  public _beneficiaryTypes: Value[];
  public newDemand: boolean = false;
  public isDuplicate: boolean = false;
  public showBlockUrba: boolean = false;

  public ouvrageTypes$ = new BehaviorSubject<Value[]>([]);
  public _ouvrageTypes: Value[];
  public objectTypes$ = new BehaviorSubject<Value[]>([]);
  public _objectTypes: DemandObject[];
  public impactTypes$ = new BehaviorSubject<Value[]>([]);
  public _impactTypes: Value[];
  isListWithout: boolean = false;
  withoutObjectSelected: boolean = false;
  public _userContracts: any[];
  public _contractZones: Zone[];
  public availableContracts: any[];
  public _activityTypes: any[];

  public urbaDocType = [
    'CU (Certificat d\'urbanisme)',
    'PC (Permis de construire)',
    'DP (Demande préalable)',
    'PA  (Permis d\'aménager)',
  ];
  public urbaDocMotif = [
    'Construction neuve',
    'Travaux sur construction existante',
    'Création de lotissement',
    'Autre',
  ];

  editionMode = true;
  beforeSendMode: boolean = false;
  saveBtnLoading: boolean = false;
  saveSendBtnLoading: boolean = false;

  private isMobile: boolean = !!navigator.userAgent.match(
    /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i,
  );

  private locationAdditionalInput = [
    'location_street_number',
    'location_street_type',
    'location_route',
    'location_postal_code',
    'location_locality',
  ];
  private enableEditLocation = true;
  toggle_feature_internal_comment = environment.toggle_feature_internal_comment;
  toggle_feature_send_ilotier = environment.toggle_feature_send_ilotier;
  toggle_feature_communication_channel = environment.toggle_feature_communication_channel;
  toggle_feature_internal_reference = environment.toggle_feature_internal_reference;
  toggle_feature_mandatory_observed_impact = environment.toggle_feature_mandatory_observed_impact;
  toogle_feature_contract_from_zone = environment.toogle_feature_contract_from_zone;
  client_name = environment.client_name;

  // LABELS
  client_to_display = environment.client_to_display;
  activity_label = environment.activity_label;
  contract_label = environment.contract_label;

  contractual_realisation_datetime: Date;

  showBranching = false;

  demandIsToSend: boolean;
  isBenificiaryChecked: boolean = true;

  isSmallScreen: boolean;

  objectTypeWithImpactWithoutObject: number[] = [];

  isLoading: boolean = true;

  // Cartographie
  @Output() onMarkerPlaced: EventEmitter<any> = new EventEmitter<any>();
  zoom: number = environment.map_zoom;
  showGeolocateButton: boolean = false
  center: google.maps.LatLngLiteral = { lat: environment.map_latitude, lng: environment.map_longitude }
  addressTypes: string[] = ['street_number', 'route', 'locality', 'postal_code']
  styleOff: google.maps.MapTypeStyle[] = [{ featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }]}, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }]}];
  styleOn: google.maps.MapTypeStyle[] = [{ featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'on' }]}, { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'on' }]}];
  mapOptions: google.maps.MapOptions =  {
    streetViewControl: false,
    styles: this.styleOn
  }
  // Marker
  markerPosition: google.maps.LatLngLiteral | undefined
  // Polyline PX
  PolylineVerticesPX: google.maps.LatLngLiteral[] = [];
  PolylineOptionsPX: google.maps.PolylineOptions = {
    strokeWeight: 2
  }
  // Polyline Classique
  PolylineVerticesT1: google.maps.LatLngLiteral[] = [];
  PolylineVerticesT2: google.maps.LatLngLiteral[] = [];
  PolylineVerticesT3: google.maps.LatLngLiteral[] = [];
  PolylineVerticesT4: google.maps.LatLngLiteral[] = [];
  PolylineVerticesT5: google.maps.LatLngLiteral[] = [];
  PolylineOptionsClassic: google.maps.PolylineOptions = {
    strokeWeight: 2
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private horaireService: HoraireService,
    private delaiService: DelaiService,
    private demandService: DemandService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakPoint: BreakpointObserver,
    private datePipe: DatePipe,
    private geocoder: MapGeocoder
  ) {
    this.now = moment(new Date()).format('YYYY-MM-DD');
    this.newDemand = router.url.includes('/new');
    this.isDuplicate = this.route.snapshot.queryParamMap.get('duplicate') !== null;
    this.mode = getUserMode();

    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 3000)
  }

  ngOnInit() {
    setTimeout(() => {
      this.constraintTelInput();
      this.createForm();
      if (this.isDuplicate) {
        this.editionMode = false;
        this.demandService.changeEditMode(false);
      }

      // For modification
      if (!this.newDemand) {
        // Reset safely location to avoid error
        if(this._demand?.location_latitude && this._demand?.location_longitude) {
          this.setLocalisation({ lat: parseFloat(this._demand?.location_latitude), lng: parseFloat(this._demand?.location_longitude) });
        }
        if (['DEMANDE_ENVOYEE', 'DEMANDE_A_COMPLETER'].includes(this._demand.workflow_current_state.code) && userIsDemandeur()) {
          if (this.demandGroup.get('realisation_datetime').value) {
            this.min = new Date(this.demandGroup.get('realisation_datetime').value);
          } else {
            this.min = undefined;
          }
        } else {
          this.min = new Date(new Date().setDate(new Date().getDate()));
        }
      }

      this.events.subscribe(() => {
        this.saveBtnLoading = false;
        this.saveSendBtnLoading = false;
      });

      if (this.route.snapshot.queryParamMap.get('beforeSendMode')) {
        this.editionMode = false;
        this.demandService.changeEditMode(false);
        this.beforeSendMode = true;
      } else if (this.isMobile && this.newDemand && !this.isDuplicate) {
        setTimeout(() => {
          this.zoom = 17;
        }, 1000);
      }
    }, 3000);
  }

  //autocomplete form with query params
  completeFormWithQueryParams() {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0){
        console.log('Init form with query params')
        if (params['X'] && params['Y']) {
          console.log('Init localisation')
          this.setLocalisation({ lat: parseFloat(params['Y']), lng: parseFloat(params['X']) });
        }
        if (params['activity_code']) {
          console.log('Init activity')
          this.demandGroup.controls['concerned_activity'].setValue(
            this._activityTypes.find((x) => x.code === params['activity_code'].toUpperCase()).id
          );
        }
        if (params['contracts']) {
          console.log('Init contract') 
          this.contract_from_url = params['contracts'].toUpperCase();
          //we check contract is in the list of available contracts
          setTimeout(() => {
            if (this._userContracts.find((x) => x.code === params['contracts'].toUpperCase())) {
              this.demandGroup.controls['contract'].setValue(params['contracts'].toUpperCase());
              this.contract_from_url = null;
            }
          },1000);
        }
        if (params['description']) {
          console.log('Init description')
          this.demandGroup.controls['description'].setValue(params['description']);
        }
        if (params['asset_id']) {
          console.log('Init asset ID')
          this.demandGroup.controls['asset_id'].setValue(params['asset_id']);
        }
        if (params['asset_label']) {
          console.log('Init asset label')
          this.demandGroup.controls['asset_label'].setValue(params['asset_label']);
        }

      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const input = document.querySelector('#adresse-autocomplete');
      const autocomplete: google.maps.places.Autocomplete = new google.maps.places.Autocomplete(input, {
        componentRestrictions: {
          country: ['fr', 're', 'gf', 'mc', 'ad']
        },
        fields: ["address_components", "adr_address", "formatted_address", "geometry", "name", "type"]
      });
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        this.setLocalisation(place.geometry.location.toJSON())
      });
      this.showGeolocateButton = true

      if (this._demand.location_latitude && this._demand.location_longitude) {
        const position = { lat: parseFloat(this._demand.location_latitude), lng: parseFloat(this._demand.location_longitude) }
        this.markerPosition = position
        this.center = position
        this.zoom = 17
      }

      this.completeFormWithQueryParams();

    }, 3000)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.demand) {
      if (this.demandGroup) {
        this.ngOnInit();
      }
    }
  }

  /**
   * Check if GPS coordinates are set. This is a custom Form Validator.
   * @returns null if set or an error Object if not set
   */
  private isPositionSet(): { addressInvalid: { value: string } } | null {
    if (this.demandGroup) {
      return (
        typeof this.demandGroup.get("location_latitude").value === "number" &&
        typeof this.demandGroup.get("location_longitude").value === "number"
        ? null
        : { addressInvalid: { value: "no coordinates set" } }
      )
    } else {
      return null
    }
  }

  public onMapReady(): void {
    if (this.isMobile) this.geolocate()
  }

  public geolocate(): void {
    navigator.geolocation.getCurrentPosition(position => {
      const location: google.maps.LatLngLiteral = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }
      this.setLocalisation(location)
    })
  }

  /**
   * Click event listener on the map allowing to set incident localisation
   * @param event object containing GPS coordinates when the map is clicked
   */
  public addMarker(event: google.maps.MapMouseEvent): boolean {
    if (this.demandGroup.controls['location_full_address'].disabled === true) {
      return false;
    }
    this.setLocalisation(event.latLng.toJSON());
  }
/**
 * Set the localisation fields and the marker on the map based
 * on the given GPS coordinates
 * @param position GPS coordinates of the marker
 */
  private setLocalisation(position: google.maps.LatLngLiteral): void {
    this.setMarker(position)
    this.setLocalisationFields(position)
  }

    /**
   * Set the marker on the map and zoom over it
   * @param position GPS coordinates of the marker
   */
  private setMarker(position: google.maps.LatLngLiteral): void {
    this.markerPosition = position
    this.center = position
    this.zoom = 17

    this.onMarkerPlaced.emit(position);
  }

    /**
     * Set the localisation fields based on the given GPS coordinates
     * @param position GPS coordinates of the marker
     */
  private setLocalisationFields(position: google.maps.LatLngLiteral): void {
    this.geocoder.geocode({ location: position }).subscribe(({results, status}) => {
      if (status === "OK" && results.length > 0) {
        const place = results[0]
        this.demandGroup.controls['location_latitude'].setValue(this.markerPosition.lat)
        this.demandGroup.controls['location_longitude'].setValue(this.markerPosition.lng)
        this.demandGroup.controls['location_full_address'].setValue(place.formatted_address)
        this.addressTypes.forEach(addressType => {
          const component = place.address_components.find(component => component.types.includes(addressType));
          if (component) {
            const formControl = this.demandGroup.controls[`location_${addressType}`]
            formControl.setValue(component.long_name)
            if (addressType === "route") {
              // set street type based on the route
              this.demandGroup.controls["location_street_type"].setValue(component.long_name.split(' ')[0])
            }
          }
        })
        // Mark address field as valid (GPS coordinates have been set)
        this.demandGroup.controls["location_full_address"].updateValueAndValidity()

        if (this.toogle_feature_contract_from_zone) {
          this.availableContracts = this._userContracts.filter((contract) => {
            if (contract.associate_shape && contract.associate_shape.length > 0) {
              if (contract.code === 'PX') {
                return this.insideTX([position.lng, position.lat], contract.associate_shape);
              }
              return this.inside(
                [position.lng, position.lat],
                contract.associate_shape,
              );
            }
            return true;
          }); 
              // Select the last one in order
          if (this.availableContracts.length > 0) {
            this.demandGroup.controls['contract'].setValue(
              this.availableContracts[this.availableContracts.length - 1].code,
            );
          } else {
            this.demandGroup.controls['contract'].reset();
          }
            // For some reason, the select is not redraw so we force it
            this.changeDetectorRef.detectChanges();
          }
        }
    })
  }

  private getPolylineVertices() {
    this.contracts.forEach((contract) => {
      contract['associate_shape'].forEach((shape) => {
        switch(contract['code']) { 
          case 'PX':
            this.PolylineVerticesPX.push({lat:shape[1],lng:shape[0]})
            this.PolylineOptionsPX.strokeColor = contract['color']
            break;
          case 'T1':
            this.PolylineVerticesT1.push({lat:shape[1],lng:shape[0]})
            this.PolylineOptionsClassic.strokeColor = contract['color']
            break;
          case 'T2':
            this.PolylineVerticesT2.push({lat:shape[1],lng:shape[0]})
            this.PolylineOptionsClassic.strokeColor = contract['color']
            break;
          case 'T3':
            this.PolylineVerticesT3.push({lat:shape[1],lng:shape[0]})
            this.PolylineOptionsClassic.strokeColor = contract['color']
            break;
          case 'T4':
            this.PolylineVerticesT4.push({lat:shape[1],lng:shape[0]})
            this.PolylineOptionsClassic.strokeColor = contract['color']
            break;
          case 'T5':
            this.PolylineVerticesT5.push({lat:shape[1],lng:shape[0]})
            this.PolylineOptionsClassic.strokeColor = contract['color']
            break;
          default:
            console.error(`Code non reconnu : ${contract['code']}`);
        }
      })
    })
  }

  inside(point: [number, number], vs) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    const x = point[0],
      y = point[1];

    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i][0], yi = vs[i][1];
      const xj = vs[j][0], yj = vs[j][1];

      const intersect = ((yi > y) != (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) { inside = !inside; }
    }

    return inside;
  }

  insideTX(point: [number, number], outerShape): boolean {
    return this.inside(point, outerShape) && this._contractZones.every((x) => !this.inside(point, x.associate_shape));
  }

  initBenificiaryTypeNational() {
    // In creation, initialize beneficiary_type
    if (this.newDemand && !this.isDuplicate && this.demandGroup) {
      this.demandGroup.controls['beneficiary_type'].setValue(
        this.getDefaultBenificiaryTypeIdNational(),
      );
    }
  }

  getDefaultBenificiaryTypeIdNational() {
    // Collectivite
    let benef;
    if (
      this._beneficiaryTypes &&
      this._beneficiaryTypes.length > 0 &&
      this.user
    ) {
      if (this.user.profile_type === 'Collectivité') {
        benef = this._beneficiaryTypes.find((b) => b.code === 'COLLECTIVITE');
      } else {
        benef = this._beneficiaryTypes.find((b) => b.code === 'INTERNE_VEOLIA');
      }
    }
    return benef ? benef.id : undefined;
  }

  initBenificiaryTypeSabom() {
    // In creation, initialize beneficiary_type
    if (this.newDemand && !this.isDuplicate && this.demandGroup) {
      this.demandGroup.controls['beneficiary_type'].setValue(
        this.getDefaultBenificiaryTypeIdSabom(),
      );
    }
  }

  getDefaultBenificiaryTypeIdSabom() {
    // if all contrats are the same type
    let benef;
    if (this._userContracts && this._userContracts.length > 0
      && this._beneficiaryTypes && this._beneficiaryTypes.length > 0) {
      if (this._userContracts.filter((uc) => uc.type === this._userContracts[0].type).length === this._userContracts.length) {
        if (this._userContracts[0].type === 'BM') {
          benef = this._beneficiaryTypes.find((b) => b.code === 'DIRECTION EAU');
        } else if (this._userContracts[0].type === 'POLE') {
          benef = this._beneficiaryTypes.find((b) => b.code === 'POLE TERRITORIAL');
        } else if (this._userContracts[0].type === 'MAIRIE') {
          benef = this._beneficiaryTypes.find((b) => b.code === 'MAIRIE');
        }
      }
    }
    return benef ? benef.id : undefined;
  }

  initConcernedActivitySabom() {
    // In creation, if all contrats are the same type, initialize concerned_activity
    if (this.newDemand && !this.isDuplicate && this._userContracts && this._userContracts.length > 0
      && this._activityTypes && this._activityTypes.length > 0
      && this.demandGroup) {
      if (this._userContracts.filter((uc) => uc.type === this._userContracts[0].type).length === this._userContracts.length) {
        // concerned_activity
        if (this._userContracts[0].type === 'BM') {
          const activityType = this._activityTypes.find(
            (b) => b.code === 'DIRECTION_EAU',
          );
          this.demandGroup.controls['concerned_activity'].setValue(
            activityType ? activityType.id : undefined,
          );
        } else if (this._userContracts[0].type === 'POLE') {
          const activityType = this._activityTypes.find(
            (b) => b.code === 'TERRITORIAL',
          );
          this.demandGroup.controls['concerned_activity'].setValue(
            activityType ? activityType.id : undefined,
          );
        } else if (this._userContracts[0].type === 'MAIRIE') {
          const activityType = this._activityTypes.find((b) => b.code === 'MAIRIE');
          this.demandGroup.controls['concerned_activity'].setValue(
            activityType ? activityType.id : undefined,
          );
        }
      }
    }
  }

  initConcernedActivitySetom() {
    // In creation, initialize concerned_activity with EAU
    if (this.newDemand && !this.isDuplicate
      && this._activityTypes && this._activityTypes.length > 0
      && this.demandGroup) {
      // concerned_activity
      setTimeout(() => {
        const activityType = this._activityTypes.find((b) => b.code === 'EAU');
        this.demandGroup.controls['concerned_activity'].setValue(activityType ? activityType.id : undefined);
      });
    }
  }

  createForm() {
    if (this.newDemand && !this.isDuplicate) {
      this._demand.is_creator_beneficiary = true;
      this._demand.beneficiary_first_name = this.user.first_name;
      this._demand.beneficiary_last_name = this.user.last_name;
      this._demand.beneficiary_email =
        this.user.email_contact && this.user.email_contact !== null
          ? this.user.email_contact
          : this.user.email;
      this._demand.beneficiary_phone = this.user.phone;
      if (this.toggle_feature_communication_channel) {
        this._demand.communication_channel = 'Application';
      }
    }

    this.demandGroup = new FormGroup({
      id: new FormControl(this._demand.id),
      is_creator_beneficiary: new FormControl(
        this._demand.is_creator_beneficiary,
      ),
      beneficiary_first_name: new FormControl(
        this._demand.beneficiary_first_name,
        [Validators.required],
      ),
      beneficiary_last_name: new FormControl(
        this._demand.beneficiary_last_name,
        [Validators.required],
      ),
      beneficiary_email: new FormControl(this._demand.beneficiary_email),
      communication_channel: new FormControl(
        this._demand.communication_channel,
        this.toggle_feature_communication_channel ? [Validators.required] : [],
      ),
      beneficiary_phone: new FormControl(this._demand.beneficiary_phone, [
        Validators.pattern(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s]*\d{2}){4}$/),
      ]),
      beneficiary_type: new FormControl(
        this.safeId(this._demand.beneficiary_type),
        [Validators.required],
      ),
      location_full_address: new FormControl(
        this._demand.location_full_address,
        [
          Validators.required,
          () => this.isPositionSet()
        ],
      ),
      location_emplacement: new FormControl(
        this.safeId(this._demand.location_emplacement),
      ),
      location_street_number: new FormControl(
        this._demand.location_street_number,
      ),
      location_street_type: new FormControl(this._demand.location_street_type),
      location_route: new FormControl(this._demand.location_route),
      location_postal_code: new FormControl(this._demand.location_postal_code),
      location_locality: new FormControl(this._demand.location_locality),
      location_longitude: new FormControl(this._demand.location_longitude),
      location_latitude: new FormControl(this._demand.location_latitude),
      concerned_activity: new FormControl(
        this.safeId(this._demand.concerned_activity),
        [Validators.required],
      ),

      contract: new FormControl(this._demand.contract, [Validators.required]),
      observed_impact: new FormControl({
        value: this.safeId(this._demand.observed_impact),
        disabled: ((this.client_name == 'national' && this._demand.demand_object === undefined && this._demand.demand_object !== null) || this.client_name == 'setom')
      }, [Validators.required]),
      // demand_object et ouvrage_type sont disable si this._demand.concerned_activity === undefined
      demand_object: new FormControl({ value: this.safeId(this._demand.demand_object), disabled: this._demand.concerned_activity === undefined }, [Validators.required]),
      ouvrage_type: new FormControl({ value: this.safeId(this._demand.ouvrage_type), disabled: this._demand.concerned_activity === undefined }),
      description: new FormControl(this._demand.description),
      actions_to_go: new FormControl(this._demand.actions_to_go),
      realisation_datetime: new FormControl(this._demand.realisation_datetime),
      external_reference_number: new FormControl(
        this._demand.external_reference_number,
      ),
      internal_comment: new FormControl(this._demand.internal_comment),
      internal_reference_number: new FormControl(
        this._demand.internal_reference_number,
      ),
      send_ilotier: new FormControl(this._demand.send_ilotier),
      attachments: new FormControl(
        this._demand.attachments ? this._demand.attachments : '',
      ),
      branching: new FormControl(this._demand.branching),
      delais: new FormControl(this._demand.delais),
      delais_type: new FormControl(this._demand.delais_type),
      delais_before_reminder: new FormControl(
        this._demand.delais_before_reminder,
      ),
      urba_type_doc: new FormControl(this._demand.urba_type_doc),
      urba_avis_nb: new FormControl(this._demand.urba_avis_nb),
      urba_avis_date: new FormControl(this._demand.urba_avis_date),
      urba_section: new FormControl(this._demand.urba_section),
      urba_parcelle_nb: new FormControl(this._demand.urba_parcelle_nb),
      urba_motif: new FormControl(this._demand.urba_motif),
      urba_motif_bis: new FormControl(this._demand.urba_motif_bis),
      asset_id: new FormControl(this._demand.asset_id),
      asset_label: new FormControl(this._demand.asset_label),
    });

    if (this.demandGroup.get('demand_object').value !== null) {
      this.subManageImpactTypes(this.demandGroup.get('demand_object').value);
      const foundObjectType = this._objectTypes.find((objectType) => objectType.id === this.demandGroup.get('demand_object').value);
      if (foundObjectType.code == 'DOC_URBA') {
        this.demandGroup.get('urba_avis_date').setValidators([Validators.required]);
        this.demandGroup.get('urba_avis_nb').setValidators([Validators.required]);
        this.demandGroup.get('urba_motif').setValidators([Validators.required]);
        this.demandGroup.get('urba_motif_bis').setValidators([Validators.required]);
        this.demandGroup.get('urba_parcelle_nb').setValidators([Validators.required]);
        this.demandGroup.get('urba_section').setValidators([Validators.required]);
        this.demandGroup.get('urba_type_doc').setValidators([Validators.required]);
        //// force recheck validators
        this.demandGroup.get('urba_avis_date').updateValueAndValidity({ emitEvent: false });
        this.demandGroup.get('urba_avis_nb').updateValueAndValidity({ emitEvent: false });
        this.demandGroup.get('urba_motif').updateValueAndValidity({ emitEvent: false });
        this.demandGroup.get('urba_motif_bis').updateValueAndValidity({ emitEvent: false });
        this.demandGroup.get('urba_parcelle_nb').updateValueAndValidity({ emitEvent: false });
        this.demandGroup.get('urba_section').updateValueAndValidity({ emitEvent: false });
        this.demandGroup.get('urba_type_doc').updateValueAndValidity({ emitEvent: false });
      }
    }

    this.demandGroup.get('demand_object').valueChanges.subscribe((demand_object_id) => {
      if (demand_object_id && demand_object_id !== null) {
        const foundObjectType = this._objectTypes.find((objectType) => objectType.id === demand_object_id);
        if (foundObjectType) {
          this.demandGroup.get('delais').setValue(foundObjectType.delais);
          this.demandGroup.get('delais_type').setValue(foundObjectType.delais_type);
          this.demandGroup.get('delais_before_reminder').setValue(foundObjectType.delais_before_reminder);

          if (foundObjectType.code === environment.object_code_for_branching) {
            this.showBranching = true;
            this.demandGroup.get('branching').setValue(1);
            this.demandGroup.get('branching').setValidators([Validators.required, Validators.min(0), Validators.max(300)]);
            this.demandGroup.get('branching').updateValueAndValidity({ emitEvent: false });
          } else {
            this.showBranching = false;
            this.demandGroup.get('branching').setValue(null);
            this.demandGroup.get('branching').setValidators([]);
            this.demandGroup.get('branching').updateValueAndValidity({ emitEvent: false });
          }
        }
      }
    });
    //  Document urbanisme //
    this.demandGroup.get('urba_motif').valueChanges.subscribe((urba_motif_value) => {
      if (urba_motif_value !== 'Construction neuve' &&
        urba_motif_value !== 'Travaux sur construction existante' &&
        urba_motif_value !== 'Création de lotissement') {
        this.demandGroup.get('urba_motif_bis').setValidators([
          Validators.required,
          Validators.max(30),
        ]);
      } else {
        this.demandGroup.get('urba_motif_bis').setValue('');
        this.demandGroup.get('urba_motif_bis').setValidators([]);
      }
    });
    ////////

    this.demandGroup
      .get('demand_object')
      .valueChanges.subscribe((demand_object_id) => {
        if (demand_object_id && demand_object_id !== null) {
          const foundObjectType = this._objectTypes.find(
            (objectType) => objectType.id === demand_object_id,
          );
          if (foundObjectType) {
            // show specific block if demand_object is 'document d'urbanisme'
            this.showBlockUrba = foundObjectType.code === 'DOC_URBA';
            this.demandGroup.get('urba_avis_date').setValidators(this.showBlockUrba ? [Validators.required] : []);
            this.demandGroup.get('urba_avis_nb').setValidators(this.showBlockUrba ? [Validators.required] : []);
            this.demandGroup.get('urba_motif').setValidators(this.showBlockUrba ? [Validators.required] : []);
            this.demandGroup.get('urba_motif_bis').setValidators(this.showBlockUrba ? [Validators.required] : []);
            this.demandGroup.get('urba_parcelle_nb').setValidators(this.showBlockUrba ? [Validators.required] : []);
            this.demandGroup.get('urba_section').setValidators(this.showBlockUrba ? [Validators.required] : []);
            this.demandGroup.get('urba_type_doc').setValidators(this.showBlockUrba ? [Validators.required] : []);
            //// force recheck validators
            this.demandGroup.get('urba_avis_date').updateValueAndValidity({ emitEvent: false });
            this.demandGroup.get('urba_avis_nb').updateValueAndValidity({ emitEvent: false });
            this.demandGroup.get('urba_motif').updateValueAndValidity({ emitEvent: false });
            this.demandGroup.get('urba_motif_bis').updateValueAndValidity({ emitEvent: false });
            this.demandGroup.get('urba_parcelle_nb').updateValueAndValidity({ emitEvent: false });
            this.demandGroup.get('urba_section').updateValueAndValidity({ emitEvent: false });
            this.demandGroup.get('urba_type_doc').updateValueAndValidity({ emitEvent: false });
            ////////////////
            this.demandGroup.get('delais').setValue(foundObjectType.delais);
            this.demandGroup
              .get('delais_type')
              .setValue(foundObjectType.delais_type);
            this.demandGroup
              .get('delais_before_reminder')
              .setValue(foundObjectType.delais_before_reminder);

            if (
              foundObjectType.code === environment.object_code_for_branching
            ) {
              this.showBranching = true;
              this.demandGroup.get('branching').setValue(1);
              this.demandGroup
                .get('branching')
                .setValidators([
                  Validators.required,
                  Validators.min(0),
                  Validators.max(300),
                ]);
              this.demandGroup
                .get('branching')
                .updateValueAndValidity({ emitEvent: false });
            } else {
              this.showBranching = false;
              this.demandGroup.get('branching').setValue(null);
              this.demandGroup.get('branching').setValidators([]);
              this.demandGroup
                .get('branching')
                .updateValueAndValidity({ emitEvent: false });
            }
          }
        } else {
          this.demandGroup.get('delais').setValue(null);
          this.demandGroup.get('delais_type').setValue(null);
          this.demandGroup.get('delais_before_reminder').setValue(null);
        }
      });

    this.demandGroup.get('branching').valueChanges.subscribe((branching) => {
      if (branching && branching !== null) {
        let delais = 0;
        let delais_type = 'CAL';
        if (branching >= 0 && branching <= 50) {
          delais = 21 * 24 * 60;
          delais_type = 'CAL';
        } else if (branching >= 51 && branching <= 100) {
          delais = 26 * 24 * 60;
          delais_type = 'CAL';
        } else if (branching >= 101 && branching <= 150) {
          delais = 31 * 24 * 60;
          delais_type = 'CAL';
        } else if (branching >= 151 && branching <= 200) {
          delais = 36 * 24 * 60;
          delais_type = 'CAL';
        } else if (branching >= 201 && branching <= 250) {
          delais = 41 * 24 * 60;
          delais_type = 'CAL';
        } else if (branching >= 251 && branching <= 300) {
          delais = 46 * 24 * 60;
          delais_type = 'CAL';
        }
        this.demandGroup.get('delais').setValue(delais);
        this.demandGroup.get('delais_type').setValue(delais_type);
        this.demandGroup
          .get('delais_before_reminder')
          .setValue((delais * 20) / 100);
      }
    });

    combineLatest(
      this.demandGroup.get('delais').valueChanges,
      this.demandGroup.get('delais_type').valueChanges,
    ).pipe(
      debounceTime(500)
    ).subscribe(([delais, delais_type]) => {
      this.calculateContractualDate();
    });

    this.demandGroup.get('concerned_activity').valueChanges.subscribe((value) => {
      const filterByActivity = (it) => it.activity === value;

      if(!this.lazyLoading) {
        this.enableAndResetField('demand_object');
        this.objectTypes$.next(_.filter(this._objectTypes, filterByActivity));

        this.enableAndResetField('ouvrage_type');
        this.ouvrageTypes$.next(_.filter(this._ouvrageTypes, filterByActivity));
      }
    });

    this.demandGroup.get('demand_object').valueChanges.subscribe((value) => {
      if(!this.lazyLoading) {
        if (value !== null) {
          this.enableAndResetField('observed_impact');
          if (this.editionMode) this._demand.observed_impact = null;
          this.subManageImpactTypes(value);
        } else {
          this.demandGroup.controls['observed_impact'].reset();
          this.demandGroup.controls['observed_impact'].disable();
        }
      }
    });

    // Disable some fields for demander and exploitant
    if (this._demand.workflow_current_state && (
      // For exploitant
      (!['DEMANDE_A_ENVOYER', 'DEMANDE_A_COMPLETER'].includes(this._demand.workflow_current_state.code) && userIsExploitant()) ||
      // For demandeur
      (!['DEMANDE_ENVOYEE', 'DEMANDE_A_ENVOYER', 'DEMANDE_A_COMPLETER'].includes(this._demand.workflow_current_state.code) && userIsDemandeur())
    )) {
      this.demandGroup.get('beneficiary_first_name').disable();
      this.demandGroup.get('beneficiary_last_name').disable();
      this.demandGroup.get('beneficiary_email').disable();
      this.demandGroup.get('beneficiary_phone').disable();
      this.demandGroup.get('external_reference_number').disable();
      this.demandGroup.get('description').disable();
      this.demandGroup.get('realisation_datetime').disable();
      this.demandGroup.get('actions_to_go').disable();

      // Disable location fields
      this.demandGroup.get('location_latitude').disable();
      this.demandGroup.get('location_longitude').disable();
      this.demandGroup.get('location_locality').disable();
      this.demandGroup.get('location_full_address').disable();
      this.demandGroup.get('location_street_number').disable();
      // this.demandGroup.get('location_emplacement').disable();
      this.demandGroup.get('location_street_type').disable();
      this.demandGroup.get('location_route').disable();
      this.demandGroup.get('location_postal_code').disable();
      this.demandGroup.get('location_postal_code').disable();
      this.demandGroup.get('location_postal_code').disable();
      this.demandGroup.get('location_postal_code').disable();
    }
    // Disable some field for demandeur
    if (this._demand.workflow_current_state && ['DEMANDE_ENVOYEE', 'DEMANDE_A_COMPLETER'].includes(this._demand.workflow_current_state.code) && userIsDemandeur()) {
      this.demandGroup.controls['contract'].disable();
    }

    if (this._demand && this._demand.demand_object && this._demand.demand_object !== null
      && this._objectTypes.length) {
      const foundObjectType = this._objectTypes.find((objectType) => objectType.id === this._demand.demand_object.id);
      if (foundObjectType) {
        if (foundObjectType.code === environment.object_code_for_branching) {
          this.showBranching = true;
          this.demandGroup.get('branching').setValidators([Validators.required, Validators.min(0), Validators.max(300)]);
          this.demandGroup.get('branching').updateValueAndValidity({ emitEvent: false });
        } else {
          this.showBranching = false;
          this.demandGroup.get('branching').setValidators([]);
          this.demandGroup.get('branching').updateValueAndValidity({ emitEvent: false });
        }
      }
      this.calculateContractualDate();
    }
    if(this._activityTypes.length === 1) {
      this.demandGroup.get('concerned_activity').setValue(this._activityTypes[0].id)
    }

    if(this.availableContracts.length === 1) {
      this.demandGroup.get('contract').setValue(this.availableContracts[0].code)
    }
    setTimeout(() => {this.lazyLoading = false},500);
  }

  toggleBeneficiary(event) {
    this.isBenificiaryChecked = event.checked;
    this.saveBtnClicked = true;
    if (!event.checked) {
      this.demandGroup.controls['beneficiary_first_name'].reset();
      this.demandGroup.controls['beneficiary_last_name'].reset();
      this.demandGroup.controls['beneficiary_email'].reset();
      this.demandGroup.controls['beneficiary_phone'].reset();
      this.demandGroup.controls['beneficiary_type'].reset();
      this.demandGroup.controls['communication_channel'].reset();
    } else {
      this.demandGroup.controls['beneficiary_first_name'].setValue(
        this.user.first_name,
      );
      this.demandGroup.controls['beneficiary_last_name'].setValue(
        this.user.last_name,
      );
      this.demandGroup.controls['beneficiary_email'].setValue(
        this.user.email_contact && this.user.email_contact !== null
          ? this.user.email_contact
          : this.user.email,
      );
      this.demandGroup.controls['beneficiary_phone'].setValue(this.user.phone);
      if (this.client_name === 'national') {
        this.demandGroup.controls['beneficiary_type'].setValue(
          this.getDefaultBenificiaryTypeIdNational(),
        );
      }
      if (this.client_name === 'sabom') {
        this.demandGroup.controls['beneficiary_type'].setValue(
          this.getDefaultBenificiaryTypeIdSabom(),
        );
      }
      if (this.toggle_feature_communication_channel) {
        this.demandGroup.controls['communication_channel'].setValue(
          'Application',
        );
      }
    }
  }

  constraintTelInput() {
    // Prevent user from typing invalid chars in the telephone input
    const allowedChars = '0123456789 +';
    document
      .querySelector('input[placeholder="Téléphone"]')
      .addEventListener('keypress', (evt: any) => {
        // allow backspace
        if (evt.key.length === 1 && !allowedChars.includes(evt.key)) {
          evt.preventDefault();
        }
      });
  }

  enableAndResetField(fieldName) {
    this.demandGroup.controls[fieldName].enable();
    this.demandGroup.controls[fieldName].reset();
  }

  goToFirstInvalidField(fieldName?: String) {
    // Get the name of the first invalid field
    let firstInvalidField;
    let invalidFieldElement: HTMLElement;
    if (fieldName) {
      invalidFieldElement = document.querySelector(`*[formControlName="${fieldName}"`,);
    } else {
      firstInvalidField = Object.entries(this.demandGroup.controls).find((f) => !f[1].valid,)[0];
      invalidFieldElement = document.querySelector(`*[formControlName="${firstInvalidField}"`,);
    }
    invalidFieldElement.parentElement.scrollIntoView(true);
    invalidFieldElement.focus();
  }

  save(isSent: boolean) {
    this.isbtnSaveClicked = true;
    this.isTryingToSendAndSave = isSent;

    this.updateValidators();
    if (!this.demandGroup.valid) {
      return this.goToFirstInvalidField();
    }
    if (!this.demandGroup.get('location_latitude').value
      || this.demandGroup.get('location_latitude').value === undefined
      || this.demandGroup.get('location_latitude').value === null
      || this.demandGroup.get('location_latitude').value === 0) {
      return this.goToFirstInvalidField('location_full_address');
    }
    this.saveBtnLoading = !isSent;
    this.saveSendBtnLoading = isSent;

    const payload = {
      demand: this.demandGroup.getRawValue(),
      isSent,
      beforeSendMode: this.beforeSendMode,
    };

    if (this.editionMode === true) {
      const message = userIsDemandeur()
        ? `Votre demande n° ${this._demand.id} a bien été mise à jour.`
        : `La demande n° ${this._demand.id} a bien été mise à jour.`;
      payload['options'] = {
        toast: {
          title: 'Demande mise à jour',
          message,
        },
        redirect: this.route.snapshot.queryParamMap.get('redirectUrl'),
      };
    }

    this.saveEvent.emit(payload);
  }

  updateValidators() {
    /* Additional field required to send demand that are not already mandatory for the draft mode */
    if (
      !this.demandGroup.get('observed_impact').disabled &&
      this.toggle_feature_mandatory_observed_impact
    ) {
      this.demandGroup
        .get('observed_impact')
        .setValidators(this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('observed_impact')
        .updateValueAndValidity({ emitEvent: false });
    }

    if (!this.demandGroup.get('demand_object').disabled) {
      const foundObjectType = this._objectTypes.find((objectType) => objectType.id === this.demandGroup.get('demand_object').value);
      if (foundObjectType !== undefined) {
        if (foundObjectType.code !== 'DOC_URBA') {
          this.demandGroup.get('urba_avis_date').setValue(null);
          this.demandGroup.get('urba_avis_nb').setValue(null);
          this.demandGroup.get('urba_type_doc').setValue(null);
          this.demandGroup.get('urba_section').setValue(null);
          this.demandGroup.get('urba_parcelle_nb').setValue(null);
          this.demandGroup.get('urba_motif').setValue(null);
          this.demandGroup.get('urba_motif_bis').setValue(null);
        }
      }
      this.demandGroup
        .get('demand_object')
        .setValidators(this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('demand_object')
        .updateValueAndValidity({ emitEvent: false });
    } else {
      this.demandGroup
        .get('demand_object')
        .setValidators(this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('demand_object')
        .updateValueAndValidity({ emitEvent: false });
    }

    if (!this.demandGroup.get('description').disabled) {
      this.demandGroup
        .get('description')
        .setValidators(this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('description')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_avis_date').disabled) {
      this.demandGroup
        .get('urba_avis_date')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('urba_avis_date')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_avis_nb').disabled) {
      this.demandGroup
        .get('urba_avis_nb')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('urba_avis_nb')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_parcelle_nb').disabled) {
      this.demandGroup
        .get('urba_parcelle_nb')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('urba_parcelle_nb')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_motif').disabled) {
      this.demandGroup
        .get('urba_motif')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('urba_motif')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_motif_bis').disabled) {
      const urbaMotifIsEmpty = this.demandGroup.get('urba_motif_bis').value == '';
      this.demandGroup
        .get('urba_motif_bis')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave && !urbaMotifIsEmpty ? [Validators.required] : []);
      this.demandGroup
        .get('urba_motif_bis')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_section').disabled) {
      this.demandGroup
        .get('urba_section')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('urba_section')
        .updateValueAndValidity({ emitEvent: false });
    }
    if (!this.demandGroup.get('urba_type_doc').disabled) {
      this.demandGroup
        .get('urba_type_doc')
        .setValidators(this.showBlockUrba && this.isTryingToSendAndSave ? [Validators.required] : []);
      this.demandGroup
        .get('urba_type_doc')
        .updateValueAndValidity({ emitEvent: false });
    }
  }

  delete() {
    this.deleteEvent.emit(this.demand);
  }

  reset() {
    this.demandGroup.reset();
  }

  linkAttachments(files: File[]) {
    this.demandGroup.controls['attachments'].setValue(files);
  }

  goHome() {
    this.router.navigate([getUserHome()]);
  }

  cancelRedirect() {
    this.demandService.changeEditMode(false);
    const redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl');
    if (redirectUrl) {
      this.router.navigateByUrl(redirectUrl);
    } else {
      this.goHome();
    }
  }

  safeId(anyObject: any) {
    if (_.has(anyObject, 'id')) {
      return anyObject.id;
    } else {
      return null;
    }
  }

  manageObjectTypes() {
    if (
      this._demand &&
      this._demand.concerned_activity &&
      this._objectTypes.length
    ) {
      this.objectTypes$.next(
        _.filter(
          this._objectTypes,
          (x) => x.activity === this.safeId(this._demand.concerned_activity),
        ),
      );
    } else {
      this.objectTypes$.next(this._objectTypes);
    }

    if (
      this._demand &&
      this._demand.demand_object &&
      this._demand.demand_object !== null &&
      this._objectTypes.length
    ) {
      // show specific block if demand_object is 'document d'urbanisme'
      this.showBlockUrba = this._demand.demand_object.code === 'DOC_URBA';
      const foundObjectType = this._objectTypes.find((objectType) => objectType.id === this._demand.demand_object.id);
      if (foundObjectType && this.demandGroup) {
        if (foundObjectType.code === environment.object_code_for_branching) {
          this.showBranching = true;
          this.demandGroup
            .get('branching')
            .setValidators([
              Validators.required,
              Validators.min(0),
              Validators.max(300),
            ]);
          this.demandGroup
            .get('branching')
            .updateValueAndValidity({ emitEvent: false });
        }
      }
      this.calculateContractualDate();
    }
  }

  manageOuvrageTypes() {
    this.ouvrageTypes$.next(
      _.filter(
        this._ouvrageTypes,
        (x) => x.activity === this.safeId(this._demand.concerned_activity),
      ),
    );
  }

  manageImpactTypes() {
    let value = this.demandGroup.get('demand_object').value;
    if (value != null) {
      this.subManageImpactTypes(value);
    }
  }

  subManageImpactTypes(value) {
    this.isListWithout = false;
    if (this.client_name == 'setom') {
      this.impactTypes$.next(this._impactTypes.filter(x => x.id == 1).map((x) => x));
      this.demandGroup.get('observed_impact').patchValue(1);
      this.demandGroup.get('observed_impact').updateValueAndValidity({ emitEvent: false });
      this.demandGroup.get('observed_impact').disable();
      this.withoutObjectSelected = true;
    } else {
      if (value !== null) {
        if (this.objectTypeWithImpactWithoutObject.includes(value) && this.client_name != 'sabom') {
          this.impactTypes$.next(this._impactTypes.filter(x => x.id == 1).map((x) => x));
          this.demandGroup.get('observed_impact').patchValue(1);
          this.demandGroup.get('observed_impact').updateValueAndValidity({ emitEvent: false });
          this.demandGroup.get('observed_impact').disable();
          this.withoutObjectSelected = true;
        } else {
          if (this.client_name == 'sabom') {
            this.impactTypes$.next(this._impactTypes);
            if (this.editionMode && this.safeId(this._demand.observed_impact) != null) {
              this.demandGroup.get('observed_impact').patchValue(this._demand.observed_impact.id);
              this.demandGroup.get('observed_impact').updateValueAndValidity({ emitEvent: false });
            }
          } else {
            this.impactTypes$.next(this._impactTypes);
            if (this.editionMode && this.safeId(this._demand.observed_impact) != null) {
              this.demandGroup.get('observed_impact').patchValue(this._demand.observed_impact.id);
              this.demandGroup.get('observed_impact').updateValueAndValidity({ emitEvent: false });
            }
            this.isListWithout = true;
          }
          this.demandGroup.get('observed_impact').updateValueAndValidity({ emitEvent: false });
        }
      }
    }
  }

  onContractChange(event: any) {
    this.selectContract.emit(event);
  }

  onContractSearch(term: string, contract: any) {
    const label = contract.code + ' - ' + contract.label;
    return label.toLowerCase().includes(term.toLowerCase());
  }

  isContractExpired(contract): boolean {
    return contract.date_fin_exploitation && contract.date_fin_exploitation < this.now;
  }

  getContractTooltip(contract): string {
    let tooltip = `${contract.code} - ${contract.label}`;
    if (this.isContractExpired(contract)) { tooltip += ` (${this.datePipe.transform(contract.date_fin_exploitation, 'dd/MM/yyyy')})`; }
    return tooltip;
  }

  calculateContractualDate() {
    this.horaireService.getHoraires().then((horaires) => {
      const delais = this.demandGroup.get('delais').value;
      const delais_type = this.demandGroup.get('delais_type').value;
      if (
        delais !== undefined &&
        delais !== null &&
        delais_type &&
        delais_type !== null
      ) {
        this.contractual_realisation_datetime = this.delaiService.calculateEndDate(
          new Date(),
          delais,
          delais_type !== 'OUV',
          horaires,
        );
      } else {
        this.contractual_realisation_datetime = undefined;
      }
    });
  }
}
