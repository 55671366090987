<mat-card appearance="outlined" class="lineo-tab-group mixed-round-border no-padding" id="skiplink-demand-nav" aria-label="Navigation demande">
  <mat-tab-group
    (selectedTabChange)="onTabChange($event)"
    [selectedIndex]="getSelectedTab()" class="demand-tab">
    <mat-tab label="DEMANDE">
      <app-demand-tab [demand]="demand"></app-demand-tab>
    </mat-tab>
    <mat-tab label="HISTORIQUE">
      <app-treatment-monitoring [demand]="demand" (reloadHistory)="reloadHistory.emit(true)" [endReload]="endReload"></app-treatment-monitoring>
    </mat-tab>
  </mat-tab-group>
</mat-card>