import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getDemandStatusLabel, getStatusLabel } from '@app/shared/helpers/demand-helper';
import { userIsExploitant, getUserMode } from '@app/shared/helpers/user-modes-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { User, UserType } from '@app/shared/models/user';
import { DelaiService } from '@app/shared/services/delai.service';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { HoraireService } from '@app/shared/services/horaire.service';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, from } from 'rxjs';
import { ExportPdfSelectionComponent } from './export-pdf-selection/export-pdf-selection.component';
import { SkipLinksService } from '@app/shared/services/skiplinks/skiplinks.service';

@Component({
  selector: 'app-indicators-dashboard',
  templateUrl: './indicators-dashboard.component.html',
  styleUrls: ['./indicators-dashboard.component.scss'],
})
export class IndicatorsDashboardComponent implements OnInit {
  constructor(
    private demandsService: DemandService,
    private authService: AuthenticationService,
    private delaiService: DelaiService,
    private horaireService: HoraireService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private skipLinksService: SkipLinksService,
  ) { }

  isLoading = true;
  allDemands;
  demands;
  getDemandStatusLabel = getDemandStatusLabel;
  getStatusLabel = getStatusLabel;

  // Filters
  isFilterPanelOpen = false;
  hasUnsavedChanges = false;

  contracts = [];
  selectedContracts = [];
  allContractsSelected = true;
  allContractsSelectedIndeterminate = false;

  objectTypes = [];
  selectedObjectTypes = [];
  allObjectTypesSelected = true;
  allObjectTypesSelectedIndeterminate = false;

  createdByList = [];
  selectedCreatedByList = [];
  allCreatedBySelected = true;
  allCreatedBySelectedIndeterminate = false;

  status = [];
  selectedStatus = [];
  allStatusSelected = true;
  allStatusSelectedIndeterminate = false;

  intervenants = [];
  selectedIntervenants = [];
  allIntervenantsSelected = true;
  allIntervenantsSelectedIndeterminate = false;

  minFilterDate;
  selectedMinFilterDate;
  maxFilterDate;
  selectedMaxFilterDate;

  contract_label = environment.contract_label;
  activity_label = environment.activity_label;

  indicatorsToPrint = [];

  indicatorList = [];
  client_name = environment.client_name;
  user: User;
  now: string;

  @ViewChild('select') slctContract;
  @ViewChild('selectStatus') slctStatus;
  @ViewChild('selectObject') slctObjet;
  @ViewChild('selectCreatedBy') slctCreatedBy;
  @ViewChild('selectIntervenants') slctIntervenants;

  ngOnInit() {
    this.isLoading = true;
    this.now = moment(new Date()).format('YYYY-MM-DD');
    this.authService.getUser().then((_user) => {
      this.user = _user as User;
      localStorage.setItem('user_profil', this.user.profile_type);
      this.indicatorList = [
        {
          id: 1,
          label: `Demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}'`,
          labelTooltip: `Nombre de demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}'`,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('1'),
        },
        {
          id: 2,
          label: `Demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}'`,
          labelTooltip: `Nombre de demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}'`,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('2'),
        },
        {
          id: 3,
          label: `Demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.A_ENVOYER))}'`,
          labelTooltip: `Nombre de demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.A_ENVOYER))}'`,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('3'),
        },
        {
          id: 4,
          label: `Demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}'`,
          labelTooltip: `Nombre de demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}'`,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('4'),
        },
        {
          id: 5,
          label: 'Demandes par statut : Demandeur',
          labelTooltip: this.client_name === 'national' ? undefined :
            `Hors statuts '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.AFFECTEE))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.EN_COURS))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.REJETEE))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' et '
                ${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}'`,
          dataType: 'graph',
          graphType: 'pie',
          columns: this.getindicatorColumnsForDataMining('5'),
        },
        {
          id: 6,
          label: 'Délai moyen de résolution (j)',
          labelTooltip: `∑ (Temps passé entre statuts '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' et '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' - Temps passés aux statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_COMPLETER))}', '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}') / ∑ des demandes dont le statut est passé à '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' au mois m (calcul en heures ouvrées). Cas de régularisation non pris en compte.`,
          dataType: 'graph',
          graphType: 'line',
          noDetail: true,
        },
        {
          id: 7,
          label: `Demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}'`,
          labelTooltip: `Nombre de demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' sur la période`,
          dataType: 'graph',
          graphType: 'column',
          columns: this.getindicatorColumnsForDataMining('7'),
          isLarge: true,
        },
        {
          id: 8,
          label: 'Demandes en cours hors délais contractuels',
          labelTooltip: `Nombre de demandes non closes (qui ne sont pas au statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}') dont la durée de traitement (∑ (Temps passé depuis le statut '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' - Temps passés aux statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_COMPLETER))}', '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}') est supérieure au délai contractuel pour ce type de demande `,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('8'),
        },
        {
          id: 9,
          label: 'Demandes hors délais contractuels',
          labelTooltip: `Nombre de demandes dont la durée de traitement (∑ Temps passé depuis le statut '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' jusqu'au statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' - Temps passés aux statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_COMPLETER))}', '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}') est supérieure au délai contractuel pour ce type de demande `,
          dataType: 'graph',
          graphType: 'column',
          columns: this.getindicatorColumnsForDataMining('9'),
        },
        {
          id: 10,
          label: `Temps passé au statut '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}' (h)`,
          labelTooltip: `Moyenne de la somme des Temps passés par chaque demande au statut '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}' `,
          dataType: 'numeric',
          noDetail: true,
        },
        {
          id: 11,
          label: 'Demandes dans les délais contractuels',
          labelTooltip: `Nombre de demandes dont la durée de traitement (∑ Temps passé depuis le statut '${_.capitalize(getStatusLabel(DemandStateCode.ENVOYEE))}' jusqu'au statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}' - Temps passés aux statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_COMPLETER))}', '${_.capitalize(getStatusLabel(DemandStateCode.SUSPENDUE))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON))}', '${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}') est inférieure au délai contractuel pour ce type de demande `,
          dataType: 'graph',
          graphType: 'column',
          columns: this.getindicatorColumnsForDataMining('11'),
        },
        {
          id: 12,
          label: 'Demandes prioritaires au regard du délai contractuel',
          labelTooltip: `Nombre de demandes non closes (qui ne sont pas au statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}') dont l'échéance contractuelle est proche (moins de 5 jours) ou dépassée - Liste triée par échéance contractuelle`,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('12'),
          sort: { active: 'contractual_realisation_datetime', direction: 'asc' },
        },
        {
          id: 13,
          label: 'Demandes par objet',
          labelTooltip: `Gris : demandes closes (statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}') - Orange : demandes non closes`,
          dataType: 'graph',
          graphType: 'column',
          isLarge: true,
          detail: 'graph',
        },
        {
          id: 14,
          label: 'Demandes par ' + this.contract_label.toLowerCase(),
          labelTooltip: `Gris : demandes closes (statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}') - Orange : demandes non closes`,
          dataType: 'graph',
          graphType: 'column',
          isLarge: true,
          detail: 'graph',
        },
        {
          id: 15,
          label: 'Demandes par demandeur',
          labelTooltip: `Gris : demandes closes (statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}' ou '${_.capitalize(getStatusLabel(DemandStateCode.ABANDONNEE))}') - Orange : demandes non closes`,
          dataType: 'graph',
          graphType: 'column',
          isLarge: true,
          detail: 'graph',
        },
        {
          id: 16,
          label: 'Demandes par statut : Exploitant',
          labelTooltip: this.client_name === 'national' ? undefined :
            `Hors statuts '${_.capitalize(getStatusLabel(DemandStateCode.A_AFFECTER))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.A_COMPLETER))}', '
                ${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_ABANDON))}' et '
                ${_.capitalize(getStatusLabel(DemandStateCode.EN_ATTENTE_CLOTURE))}'`,
          dataType: 'graph',
          graphType: 'pie',
          columns: this.getindicatorColumnsForDataMining('16'),
        },
        {
          id: 17,
          label: `Demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}'`,
          labelTooltip: `Nombre de demandes au statut '${_.capitalize(getStatusLabel(DemandStateCode.CLOTUREE))}'`,
          dataType: 'numeric',
          columns: this.getindicatorColumnsForDataMining('17'),
        },
        {
          id: 18,
          label: 'Demandes actives, sans activité depuis plus de 4 mois',
          labelTooltip: 'Demande active (hors demandes aux statuts clôturée, abandonnée, à envoyer) dont la date de dernière activité est >=4mois',
          dataType: 'graph',
          graphType: 'column',
          isLarge: false,
          columns: this.getindicatorColumnsForDataMining('18'),
        },
        {
          id: 19,
          label: 'Niveau de satisfaction moyen du traitement des demandes',
          labelTooltip: 'Moyenne des notes reçues sur les demandes',
          dataType: 'review',
          columns: this.getindicatorColumnsForDataMining('19'),
        },
        {
          id: 20,
          label: 'Demandes reçues',
          labelTooltip: "Nombre de demandes passées au statut 'Envoyée' sur la période",
          dataType: 'graphAndNumber',
          graphType: 'column',
          isLarge: true,
          columns: this.getindicatorColumnsForDataMining('20'),
        },
      ];
    });

    this.demandsService.getObjectTypes().subscribe((objectTypes) => {
      // Object Types are by Demand type, do a distinct on the label to filter them
      const seen = {};
      objectTypes = objectTypes.filter(function (item) {
        return seen.hasOwnProperty(item.label) ? false : (seen[item.label] = true);
      }).sort((a, b) => a.label.localeCompare(b.label));

      this.objectTypes = objectTypes;
      this.selectedObjectTypes = this.objectTypes.map((objectType) => objectType.label);
    });

    combineLatest(this.authService.getUserContracts(), from(this.demandsService.getNewAllDemands(UserType.DEMANDEUR))).subscribe(([contracts, demands]) => {

      const demandList = demands.filter((demand: any) => {
        if (
          demand.last_transition_created_at !== null &&
          demand.last_transition_created_at &&
          (
            demand.workflow_current_state_code == 'DEMANDE_A_ENVOYER' ||
            demand.workflow_current_state_code == 'DEMANDE_CLOTUREE' ||
            demand.workflow_current_state_code == 'DEMANDE_ABANDONNEE'
          )) {
          return moment(demand.last_transition_created_at).isAfter(moment().add(-53, 'weeks'));
        }
        return true;
      });
      this.allDemands = demandList;
      this.demands = demandList;
      this.minFilterDate = undefined;

      this.contracts = contracts.filter((contract) => this.allDemands.map(x => x.contract).includes(contract.code))
      this.contracts = this.contracts.sort((a, b) => (a.position - b.position) == 0 ? a.code.localeCompare(b.code) : (a.position - b.position));
      this.selectedContracts = this.contracts.map((contract) => contract.code);

      // Create a list of created by
      let seen = {};
      this.createdByList = this.demands.filter((demand) => {
        const key = demand.created_by;
        return seen.hasOwnProperty(key) ? false : (seen[key] = true);
      })
        .map((demand) => ({ code: demand.created_by, label: demand.created_by }))
        .sort((a, b) => a.label.localeCompare(b.label));
      this.selectedCreatedByList = this.createdByList.map((createdBy) => createdBy.code);

      // Create a list of status
      seen = {};
      this.status = this.demands.filter((demand) => {
        const key = demand.workflow_current_state_code;
        return seen.hasOwnProperty(key) ? false : (seen[key] = true);
      })
        .map((demand) => ({ code: demand.workflow_current_state_code, label: getDemandStatusLabel(demand.workflow_current_state_code) }))
        .sort((a, b) => a.label.localeCompare(b.label));
      this.selectedStatus = this.status.map((status) => status.code);

      // Create a list of intervenants
      seen = {};
      this.intervenants = this.demands.filter((demand) => {
        const key = demand.intervenant;
        if (key === null || key === undefined || key === '') { return false; }
        return seen.hasOwnProperty(key) ? false : (seen[key] = true);
      })
        .map((demand) => ({ code: demand.intervenant, label: demand.intervenant }))
        .sort((a, b) => a.label.localeCompare(b.label));
      // this.intervenants.push({code: "undefined_intervenant", label: "Pas d'intervenant"})
      this.selectedIntervenants = this.intervenants.map((intervenant) => intervenant.code);

      // Get min date
      this.demands.forEach((demand) => {
        if (demand.send_date_str && demand.send_date_str !== null) {
          const date = moment(demand.send_date_str);
          if (!this.minFilterDate || date.isBefore(moment(this.minFilterDate))) {
            this.minFilterDate = date.toDate();
          }
        }
      });
      this.selectedMinFilterDate = this.minFilterDate;
      this.maxFilterDate = moment().toDate();
      this.selectedMaxFilterDate = this.maxFilterDate;
      this.horaireService.getHoraires().then((horaires) => {
        // Stats by demand
        this.demandsService.getStatistics().then((statistics: any[]) => {
          const mapStatsByDemand = [];

          statistics.forEach((stat) => {
            if (mapStatsByDemand[stat.demand_id]) {
              mapStatsByDemand[stat.demand_id].push(stat);
            } else {
              mapStatsByDemand[stat.demand_id] = [stat];
            }
          });

          this.demands.forEach((demand) => {
            demand.statistics = mapStatsByDemand[demand.id];

            // Init time for each status
            for (const status of Object.keys(DemandStateCode)) {
              demand['time_' + DemandStateCode[status]] = 0;
            }

            let activeTime = 0;
            let holdTime = 0;

            if (!demand.statistics) { demand.statistics = []; }

            // Add the current status time
            if (demand.last_transition_created_at !== null) {
              demand.statistics.push({
                demand_id: demand.id,
                source_state_code: demand.workflow_current_state_code,
                start_date: moment(demand.last_transition_created_at).format('YYYY-MM-DD[T]HH:mm:ss'),
                end_date: moment().format('YYYY-MM-DD[T]HH:mm:ss'),
                delais_type: demand.delais_type,
              });
            }

            demand.statistics.forEach((stat) => {
              stat.time_diff = this.delaiService.calculateTimeSpent(
                new Date(stat.start_date),
                new Date(stat.end_date),
                demand.delais_type !== 'OUV',
                horaires,
              );

              const time = stat.time_diff !== null ? stat.time_diff : 0;
              if (stat.source_state_code !== DemandStateCode.SUSPENDUE
                && stat.source_state_code !== DemandStateCode.A_COMPLETER
                && stat.source_state_code !== DemandStateCode.A_ENVOYER
                && stat.source_state_code !== DemandStateCode.EN_ATTENTE_ABANDON
                && stat.source_state_code !== DemandStateCode.EN_ATTENTE_CLOTURE
                && stat.source_state_code !== DemandStateCode.ABANDONNEE
                && stat.source_state_code !== DemandStateCode.CLOTUREE) {
                activeTime += time;
              } else if (stat.source_state_code !== DemandStateCode.A_ENVOYER
                && stat.source_state_code !== DemandStateCode.ABANDONNEE
                && stat.source_state_code !== DemandStateCode.CLOTUREE) {
                holdTime += time;
              } else {
                // A Envoyer, Abandonnee and cloturee are not active nor hold
              }

              demand['time_' + stat.source_state_code] += time;
            });

            demand.active_time = activeTime;
            demand.hold_time = holdTime;

            // Calculate the time left
            const timeLeft = demand.delais - demand.active_time;
            demand.timeLeft = timeLeft;

            if (demand.send_date_str && demand.send_date_str !== null) {
              demand.contractual_realisation_datetime = moment(this.delaiService.calculateEndDate(
                new Date(demand.send_date_str),
                demand.delais + holdTime,
                demand.delais_type !== 'OUV',
                horaires,
              ));
            }
          });

          // Get the list of indicators to display
          let indicatorIdsToDisplay = environment.indicators_to_display_demandeur;
          if (userIsExploitant()) {
            indicatorIdsToDisplay = environment.indicators_to_display_exploitant;
          }

          // Filter and order the list of indicators to display
          this.indicatorList = this.indicatorList.filter((indicator) => {
            return indicatorIdsToDisplay.includes(indicator.id);
          })
            .sort((a, b) => {
              return indicatorIdsToDisplay.indexOf(a.id) - indicatorIdsToDisplay.indexOf(b.id);
            });

          this.indicatorsToPrint = this.indicatorList.map((i) => i.id);

          this.isLoading = false;
          this.skipLinksService.next();
          this.loadFilterConfiguration();
        });
      });
    });
  }

  // return columns list for indicator data mining
  // if special columns not set return default columns for current view (demandeur vs exploitant)
  getindicatorColumnsForDataMining(IndicatorId: String) {
    const demandeur_x_columns = 'indicator_' + IndicatorId + '_demandeur_columns';
    const exploitant_x_columns = 'indicator_' + IndicatorId + '_exploitant_columns';
    if (environment[demandeur_x_columns] && environment[exploitant_x_columns]) {
      return (this.user.profile_type === 'Collectivité' ? environment[demandeur_x_columns] : environment[exploitant_x_columns]);
    } else {
      return (this.user.profile_type === 'Collectivité' ? environment.indicator_default_demandeur_columns : environment.indicator_default_exploitant_columns);
    }
  }

  /** FILTERS */
  onContractsChange() {
    if (this.selectedContracts.length === 0) {
      this.allContractsSelected = false;
      this.allContractsSelectedIndeterminate = false;
    } else if (this.selectedContracts.length === this.contracts.length) {
      this.allContractsSelected = true;
      this.allContractsSelectedIndeterminate = false;
    } else {
      this.allContractsSelected = false;
      this.allContractsSelectedIndeterminate = true;
    }
    this.hasUnsavedChanges = true;
  }

  selectAllContracts() {
    if (this.allContractsSelected) {
      this.selectedContracts = this.slctContract.itemsList._filteredItems.map((x) => x.value.code);
    } else {
      this.selectedContracts = [];
    }
    this.hasUnsavedChanges = true;

  }

  onObjectTypesChange() {
    if (this.selectedObjectTypes.length === 0) {
      this.allObjectTypesSelected = false;
      this.allObjectTypesSelectedIndeterminate = false;
    } else if (this.selectedObjectTypes.length === this.objectTypes.length) {
      this.allObjectTypesSelected = true;
      this.allObjectTypesSelectedIndeterminate = false;
    } else {
      this.allObjectTypesSelected = false;
      this.allObjectTypesSelectedIndeterminate = true;
    }
    this.hasUnsavedChanges = true;
  }

  selectAllObjectTypes() {
    if (this.allObjectTypesSelected) {
      this.selectedObjectTypes = this.slctObjet.itemsList._filteredItems.map((x) => x.value.label);
    } else {
      this.selectedObjectTypes = [];
    }
    this.hasUnsavedChanges = true;
  }

  onCreatedByChange() {
    if (this.selectedCreatedByList.length === 0) {
      this.allCreatedBySelected = false;
      this.allCreatedBySelectedIndeterminate = false;
    } else if (this.selectedCreatedByList.length === this.createdByList.length) {
      this.allCreatedBySelected = true;
      this.allCreatedBySelectedIndeterminate = false;
    } else {
      this.allCreatedBySelected = false;
      this.allCreatedBySelectedIndeterminate = true;
    }
    this.hasUnsavedChanges = true;
  }

  selectAllCreatedBys() {
    if (this.allCreatedBySelected) {
      this.selectedCreatedByList = this.slctCreatedBy.itemsList._filteredItems.map((x) => x.value.code);
    } else {
      this.selectedCreatedByList = [];
    }
    this.hasUnsavedChanges = true;
  }

  onStatusChange() {
    if (this.selectedStatus.length === 0) {
      this.allStatusSelected = false;
      this.allStatusSelectedIndeterminate = false;
    } else if (this.selectedStatus.length === this.status.length) {
      this.allStatusSelected = true;
      this.allStatusSelectedIndeterminate = false;
    } else {
      this.allStatusSelected = false;
      this.allStatusSelectedIndeterminate = true;
    }
    this.hasUnsavedChanges = true;
  }

  selectAllStatus() {
    if (this.allStatusSelected) {
      this.selectedStatus = this.slctStatus.itemsList._filteredItems.map((x) => x.value.code);
    } else {
      this.selectedStatus = [];
    }
    this.hasUnsavedChanges = true;
  }

  onIntervenantsChange() {
    if (this.selectedIntervenants.length === 0) {
      this.allIntervenantsSelected = false;
      this.allIntervenantsSelectedIndeterminate = false;
    } else if (this.selectedIntervenants.length === this.intervenants.length) {
      this.allIntervenantsSelected = true;
      this.allIntervenantsSelectedIndeterminate = false;
    } else {
      this.allIntervenantsSelected = false;
      this.allIntervenantsSelectedIndeterminate = true;
    }
    this.hasUnsavedChanges = true;
  }

  selectAllIntervenants() {
    if (this.allIntervenantsSelected) {
      this.selectedIntervenants = this.slctIntervenants.itemsList._filteredItems.map((x) => x.value.code);
    } else {
      this.selectedIntervenants = [];
    }
    this.hasUnsavedChanges = true;
  }

  onSelectedMinFilterDateChange(event) {
    this.selectedMinFilterDate = event.value;
    this.hasUnsavedChanges = true;
  }

  onSelectedMaxFilterDateChange(event) {
    this.selectedMaxFilterDate = event.value;
    this.hasUnsavedChanges = true;
  }

  reinitFilter() {
    this.selectedContracts = this.contracts.map((contract) => contract.code);
    this.onContractsChange();
    this.selectedObjectTypes = this.objectTypes.map((objectType) => objectType.label);
    this.onObjectTypesChange();
    this.selectedCreatedByList = this.createdByList.map((createdBy) => createdBy.code);
    this.onCreatedByChange();
    this.selectedStatus = this.status.map((status) => status.code);
    this.onStatusChange();
    this.selectedIntervenants = this.intervenants.map((intervenant) => intervenant.code);
    this.onIntervenantsChange();
    this.selectedMinFilterDate = this.minFilterDate;
    this.selectedMaxFilterDate = this.maxFilterDate;
    this.applyFilter();
  }

  applyFilter() {
    this.demands = this.allDemands
      .filter((demand) => this.selectedContracts.includes(demand.contract))
      .filter((demand) => {
        return this.selectedObjectTypes.includes(demand.demand_object)
          || demand.demand_object === null || demand.demand_object === undefined || demand.demand_object === '';
      })
      .filter((demand) => this.selectedCreatedByList.includes(demand.created_by))
      .filter((demand) => this.selectedStatus.includes(demand.workflow_current_state_code))
      .filter((demand) => {
        if (this.selectedIntervenants.some((i) => i === 'undefined_intervenant')) {
          return this.selectedIntervenants.includes(demand.intervenant) || this.selectedIntervenants.includes(demand.assignations)
            || demand.intervenant === null || demand.intervenant === undefined || demand.intervenant === '';
        } else {
          return this.selectedIntervenants.includes(demand.intervenant) || this.selectedIntervenants.includes(demand.assignations)
            || demand.intervenant === null || demand.intervenant === undefined || demand.intervenant === '';
        }
      })
      .filter((demand) => {
        if (demand.send_date_str !== null && demand.send_date_str) {
          if (moment(demand.send_date_str).isBefore(moment(this.selectedMinFilterDate))
            || moment(demand.send_date_str).isAfter(moment(this.selectedMaxFilterDate).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 }))) {
            return false;
          }
        }
        return true;
      });
    this.saveFilterConfiguration();
    this.hasUnsavedChanges = false;
  }

  onContractSearch(term: string, contract: any) {
    const label = contract.code + ' - ' + contract.label;
    return label.toLowerCase().includes(term.toLowerCase());
  }

  onFieldSelectOpened(type) {
    if (type == 'contract') {
      this.reorderField(false, type, this.contracts, this.selectedContracts);
    } else if (type == 'status') {
      this.reorderField(false, type, this.status, this.selectedStatus);
    } else if (type == 'object') {
      this.reorderField(false, type, this.objectTypes, this.selectedObjectTypes);
    } else if (type == 'createdByList') {
      this.reorderField(false, type, this.createdByList, this.selectedCreatedByList);
    } else if (type == 'intervenant') {
      this.reorderField(false, type, this.intervenants, this.selectedIntervenants);
    }
    setTimeout(() => {
      const scrollContainer = document.querySelector('.ng-dropdown-panel-items');
      if (scrollContainer) {
        scrollContainer.scrollTop = 0;
      }
    }, 50);
  }

  reorderField(onSearchInputChange: boolean = true, type, fieldType, selectFieldType) {

    let compareValue = 'code';
    if (type == 'object' || type == 'intervenant') { compareValue = 'label'; }

    if (!onSearchInputChange) {
      fieldType = fieldType.sort((a, b) => {
        if (a[compareValue] < b[compareValue]) { return -1; }
        if (a[compareValue] > b[compareValue]) { return 1; }
      });
    }

    fieldType.sort((a, b) => {
      if (!selectFieldType.includes(a[compareValue]) && selectFieldType.includes(b[compareValue])) { return 1; }
      if (selectFieldType.includes(a[compareValue]) && !selectFieldType.includes(b[compareValue])) { return -1; }
      return 0;
    });

    if (type == 'contract') { this.contracts = fieldType.slice(); }
    if (type == 'status') { this.status = fieldType.slice(); }
    if (type == 'object') { this.objectTypes = fieldType.slice(); }
    if (type == 'intervenant') { this.intervenants = fieldType.slice(); }
    if (type == 'createdByList') { this.createdByList = fieldType.slice(); }
  }

  isContractExpired(contract): boolean {
    return contract.date_fin_exploitation && contract.date_fin_exploitation < this.now;
  }

  getContractTooltip(contract) {
    let tooltip = `${contract.code} - ${contract.label}`;
    if (this.isContractExpired(contract)) { tooltip += ` (${this.datePipe.transform(contract.date_fin_exploitation, 'dd/MM/yyyy')})`; }
    return tooltip;
  }

  onPrint() {
    const dialogRef = this.dialog.open(ExportPdfSelectionComponent, {
      data: this.indicatorList,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.indicatorsToPrint = result;
        setTimeout(() => {
          this.print();
          this.indicatorsToPrint = this.indicatorList.map((i) => i.id);
        });
      }
    });
  }

  print() {
    const documentCurrentTitle = document.title;
    // change page rename
    const dateNow = moment().format('YYYYMMDD_HHmmss');
    document.title = `indicateurs_${environment.app_name_short}_${dateNow}.pdf`;
    // complete page with only print info
    const messageTitle = document.querySelector('.indicator-print-title');
    messageTitle.innerHTML = '<h2><b>Période : </b> Du ' + moment(this.selectedMinFilterDate).format('DD/MM/YYYY') + ' Au ' + moment(this.selectedMaxFilterDate).format('DD/MM/YYYY') + '</h2>';
    const messageElement = document.querySelector('.indicator-print-infos');
    if (messageElement) {
      messageElement.innerHTML = '<h2> Filtres appliqués </2><br><br>';
      messageElement.innerHTML += '<b>' + this.contract_label + ' : </b>';
      this.selectedContracts.forEach((element, index) => {
        if (index <= 50) {
          messageElement.innerHTML += element + '-' + this.contracts.find((contract) => contract.code === element).label + ' ; ';
        }
        if (index === 51) {
          messageElement.innerHTML += '- plus de 50 contrats... ; ';
        }
      });
      messageElement.innerHTML += '<hr> <b>Objet de la demande : </b>';
      this.selectedObjectTypes.forEach((element) => {
        messageElement.innerHTML += element + ' ; ';
      });
      if (environment.client_name !== 'national') {
        messageElement.innerHTML += '<hr> <b>Demandeur : </b>';
        this.selectedCreatedByList.forEach((element) => {
          messageElement.innerHTML += element + ' ; ';
        });
      }
      // TODO : Filtre status
      messageElement.innerHTML += '<hr> <b>Statut : </b>';
      this.selectedStatus.forEach((element) => {
        messageElement.innerHTML += this.status.find((s) => s.code === element).label + ' ; ';
      });
      if (environment.client_name !== 'national') {
        // TODO : Filtre intervenants
        messageElement.innerHTML += '<hr> <b>Intervenant : </b>';
        this.selectedIntervenants.forEach((element) => {
          messageElement.innerHTML += element + ' ; ';
        });
      }
    }
    // browser print fonction
    window.print();
    // retore page name
    document.title = documentCurrentTitle;
  }

  saveFilterConfiguration() {
    let conf = {
      "contracts":this.allContractsSelected ? null : this.selectedContracts,
      "status":this.allStatusSelected ? null : this.selectedStatus,
      "objectTypes":this.allObjectTypesSelected ? null : this.selectedObjectTypes,
      "createdByList":this.allCreatedBySelected ? null : this.selectedCreatedByList,
      "intervenants":this.allIntervenantsSelected ? null : this.selectedIntervenants,
      // "minFilterDate":this.selectedMinFilterDate,
      // "maxFilterDate":this.selectedMaxFilterDate
    }
    localStorage.setItem(`indicator-filters`, JSON.stringify(conf));
  }
  loadFilterConfiguration() {
    this.hasUnsavedChanges = true;
    const savedConf = localStorage.getItem(`indicator-filters`);
    if (savedConf !== undefined && savedConf !== null) {
      let indicatorFilters = JSON.parse(savedConf);
      indicatorFilters.contracts != null ? (this.selectedContracts = indicatorFilters.contracts, this.allContractsSelected = false) : {}
      indicatorFilters.status != null ? (this.selectedStatus = indicatorFilters.status, this.allStatusSelected = false) : {}
      indicatorFilters.objectTypes != null ? (this.selectedObjectTypes = indicatorFilters.objectTypes, this.allObjectTypesSelected = false) : {}
      indicatorFilters.createdByList != null ? (this.selectedCreatedByList = indicatorFilters.createdByList, this.allCreatedBySelected = false) : {}
      indicatorFilters.intervenants != null ? (this.selectedIntervenants = indicatorFilters.intervenants, this.allIntervenantsSelected = false) : {}
      // indicatorFilters.minFilterDate != null ? (this.selectedMinFilterDate = indicatorFilters.minFilterDate) : {}
      // indicatorFilters.maxFilterDate != null ? (this.selectedMaxFilterDate = indicatorFilters.maxFilterDate) : {}
    }
    this.applyFilter();
  }
}
