import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import Toast from '@app/shared/helpers/toast';
import { environment } from '../../environments/environment';
import { User } from '@app/shared/models/user';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { ResetPasswordModalComponent } from '@app/components/modals/reset-password-modal/reset-password-modal.component';
import { MatDialog } from '@angular/material/dialog';

declare const gapi: any;

@Component({
  selector: 'app-authentication',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  version: string = environment.VERSION;
  revision: string = environment.REVISION;
  isProdMode: boolean = environment.production;
  client_name: string = environment.client_name;
  toggle_feature_publik_connexion: boolean = environment.toggle_feature_publik_connexion;

  isChangePassword = false;
  changePasswordForm: FormGroup;
  changePasswordSubmitted = false;
  isPasswordsDifferent = false;
  isResetPassword: boolean = false;
  oldPasswordToken: string;

  get f() { return this.loginForm.controls; }
  get cpf() { return this.changePasswordForm.controls; }

  googleAuth;
  authenticationTypes = environment.authentication_types;

  showBtn: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private zone: NgZone,
    private demandService: DemandService,
    public dialog: MatDialog,
  ) {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId: environment.client_id,
        cookiepolicy: 'single_host_origin',
        scope: 'profile email',
      })
        .then(() => {
          this.zone.run(() => {
            this.googleAuth = gapi.auth2.getAuthInstance();
          });
        });
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params['token'] && !params['email']) {
        console.log("params['token'] && !params['email']")
        if (localStorage.getItem('isPublik') == 'true') {
          this.authenticationService.authentication_types = 'publik_internal';
        } else {
          this.authenticationService.authentication_types = 'keycloak_internal';
        }
        this.authenticationService.setToken(params['token']);
        console.log('gotoStartPage After token');
        this.demandService.gotoStartPage();
      }
    });

    if (this.onlyAuthentificationKeycloak() && !this.hasToken()) {
      this.keycloak_internalSignIn();
    }/* else if (this.onlyAuthentificationKeycloak() && this.hasToken()) {
      console.log('gotoStartPage After onlyAuthentificationKeycloak');
      setTimeout(() => {
        this.demandService.gotoStartPage();
      },1000);
    }*/


    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.changePasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      oldPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });

    // Password reset
    if (this.route.snapshot.queryParams['token']) {
      this.oldPasswordToken = this.route.snapshot.queryParams['token'];
      this.changePasswordForm.get('username').setValue(this.route.snapshot.queryParams['email']);
      // Dummy password to fill the field like a user would normally do
      this.changePasswordForm.get('oldPassword').setValue('password');
      this.isChangePassword = true;
      this.isResetPassword = true;
    }

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.showBtn = true;
    });
  }

  onSubmit() {
    this.authenticationService.authentication_types = 'internal_internal';
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.demandService.resetMemoryData();
          this.router.navigate([this.returnUrl]);
        },
        (error) => {
          Toast.error('Email et mot de passe incorrects');
          this.loading = false;
        });
  }

  onChangePassword() {
    this.changePasswordSubmitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }

    const values = this.changePasswordForm.value;
    this.isPasswordsDifferent = values.password !== values.confirmPassword;

    if (this.isPasswordsDifferent) {
      return;
    }

    this.loading = true;

    if (this.oldPasswordToken !== undefined) {
      this.authenticationService.changePasswordByToken(this.oldPasswordToken, values.password)
        .then(() => {
          Toast.info('Votre mot de passe a bien été modifié');
          this.changePasswordForm.reset();
          this.changePasswordSubmitted = false;
          this.isResetPassword = false;
          this.isChangePassword = false;
          this.loading = false;
          this.router.navigate(['/login']);
        })
        .catch((err) => {
          Toast.error('Une erreur s\'est produite');
          this.loading = false;
        });
    } else {
      this.authenticationService.changePassword(values.username, values.oldPassword, values.password)
        .pipe(first())
        .subscribe(
          (data) => {
            Toast.info('Votre mot de passe a bien été modifié');
            this.router.navigate([this.returnUrl]);
          },
          (error) => {
            Toast.error('Une erreur s\'est produite');
            this.loading = false;
          });
    }
  }

  onlyAuthentificationKeycloak() {
    return this.authenticationTypeOk('keycloak_internal') && this.authenticationTypes.length === 1;
  }

  hasToken() {
    return localStorage.getItem('token');
  }

  authenticationTypeOk(authTypeToCheck) {
    return this.authenticationTypes.indexOf(authTypeToCheck) !== -1;
  }

  google_internalSignIn() {
    this.authenticationService.authentication_types = 'google_internal';
    this.googleSignIn();
  }

  google_habiliaSignIn() {
    this.authenticationService.authentication_types = 'google_habilia';
    this.googleSignIn();
  }

  googleSignIn() {
    this.googleAuth.signIn({
      // Show the prompt
      prompt: 'consent',
    })
      .then((googleUser) => {
        this.zone.run(() => {
          this.afterGoogleLogin(googleUser);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  publik_internalSignIn() {
    this.authenticationService.authentication_types = 'publik_internal';
    this.publikSignIn();
  }
  publikSignIn() {
    this.authenticationService.loginPublik();
  }

  keycloak_internalSignIn() {
    this.authenticationService.authentication_types = 'keycloak_internal';
    this.keycloakSignIn();
  }

  keycloakSignIn() {
    this.authenticationService.loginKeycloak();
  }

  afterGoogleLogin(googleUser) {
    this.authenticationService.setToken(googleUser.getAuthResponse().id_token);
    this.authenticationService.getUser().then((user: User) => {
      const profile = googleUser.getBasicProfile();
      /*user.firstname = profile.getGivenName();
      user.lastname = profile.getFamilyName();
      user.avatar = profile.getImageUrl();*/
      user.profile_picture = profile.getImageUrl();
      this.authenticationService.setUserData(user);
      this.demandService.resetMemoryData();
      console.log('gotoStartPage afterGoogleLogin');
      this.demandService.gotoStartPage();
    },
      (err) => {
        this.router.navigate(['/not-authorized']);
      });
  }

  resetPassword() {
    this.dialog.open(ResetPasswordModalComponent, {
      disableClose: false,
      data: { username: this.loginForm.get('username').value },
      autoFocus: true,
      width: '500px',
    });
  }
}
