import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { getUserMode } from '@app/shared/helpers/user-modes-helper';
import { DemandStateCode } from '@app/shared/models/demand-state';
import { Router } from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import { environment } from '@env/environment';
import { convertDelai, getDemandStatusLabel } from '@app/shared/helpers/demand-helper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DecimalFormatPipe } from '@app/pipes/decimal.pipe';

@Component({
  selector: 'app-indicator-detail',
  templateUrl: './indicator-detail.component.html',
  styleUrls: ['./indicator-detail.component.scss'],
})
export class IndicatorDetailComponent implements OnInit {
  public currentUrl: string = '';
  private hasIdBackgroundColor = environment.toggle_feature_id_color;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<IndicatorDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private demandService: DemandService,
  ) { }

  data = [];
  demands = [];
  customSort;
  isLoading = true;

  getDemandStatusLabel = getDemandStatusLabel;

  client_is_setom = environment.client_name === 'setom' ? true : false;
  user_is_col = localStorage.getItem('user_profil') === 'collectivité' ? true : false;

  interne_object_list = [
    'Autres interventions',
    'Déclaration anomalie qualité d\'eau',
    'Demande client',
    'Enrobés / affaissement',
    'Incendie - Signalement anomalie PIBI',
    'Incident périmètre de protection des prises d\'eau',
    'Intégration domaine public',
    'Interventions sur ouvrage et réseau',
    'Manoeuvre de vannes',
    'Mise à la cote/renouvellement fonte',
    'Problème de pression',
    'Technico-administratif',
    'Utilisation BPU',
    'Visite ouvrage',
  ];
  col_object_list = [
    'Autres interventions',
    'Déclaration anomalie qualité d\'eau',
    'Demande client',
    'Enrobés / affaissement',
    'Incident périmètre de protection des prises d\'eau',
    'Information nouveau chantier TM',
    'Intégration domaine public',
    'Interventions sur ouvrage et réseau',
    'Manoeuvre de vannes',
    'Mise à la cote/renouvellement fonte',
    'Problème de pression',
    'Suspicion fuite',
    'Technico-administratif',
    'Utilisation BPU',
    'Visite ouvrage',
  ];
  setom_object_list = this.user_is_col ? this.col_object_list : this.interne_object_list;

  column_label = this.client_is_setom ? 'Date réelle de réalisation' : 'Date réelle de clôture';


  columns = [
    {
      label: 'N°',
      key: 'id',
      active: true,
      activable: false,
      sticky: true,
      sortable: true,
      filter: 'number',
      backgroundColor: (item): string => this.getIdBackgroundColor(item),
    },
    {
      label: 'Note',
      key: 'reviews',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: 'Demandeur',
      key: 'created_by',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: environment.activity_label,
      key: 'concerned_activity',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: environment.contract_label,
      key: 'contract',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: 'Objet de la demande',
      key: 'demand_object',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: 'Impact de la nuisance observée',
      key: 'observed_impact',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: 'Adresse',
      key: 'location_full_address',
      active: true,
      activable: true,
      sortable: true,
    },
    {
      label: 'Commune',
      key: 'location_locality',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: 'Date de réception',
      key: 'reception_date',
      active: true,
      activable: true,
      sortable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Infos',
      key: 'infos',
      sortable: true,
      active: true,
      activable: true,
      filter: 'select',
    },
    {
      label: 'Date d\'envoi',
      key: 'send_date',
      active: true,
      sortable: true,
      activable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Echéance souhaitée',
      key: 'realisation_datetime',
      active: true,
      sortable: true,
      activable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Date réelle de réception',
      key: 'receive_date',
      active: false,
      sortable: false,
      activable: false,
      filter: 'date',
      type: 'date',
    },
    {
      label: this.column_label,
      key: 'close_date',
      active: false,
      sortable: false,
      activable: false,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Date passage statut',
      key: 'last_transition_created_at',
      active: true,
      sortable: true,
      activable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Date dernière activité',
      key: 'updated_at',
      active: true,
      sortable: true,
      activable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Statut',
      key: 'workflow_current_state',
      active: true,
      activable: true,
      sortable: true,
      filter: 'select',
    },
    {
      label: 'Demande prise en charge par',
      key: 'in_charge',
      active: true,
      sortable: true,
      activable: true,
      filter: 'select',
    },
    {
      label: 'Référence externe',
      key: 'external_reference_number',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number'
    },
    {
      label: 'Description',
      key: 'description',
      active: true,
      sortable: true,
      activable: true,
    },
    {
      label: 'Action',
      key: 'action',
      sortable: true,
      active: true,
      activable: false,
      stickyEnd: true,
      filter: 'none',
    },
    {
      label: 'Délai contractuel (minutes)',
      key: 'delais',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Type de délai',
      key: 'delais_type',
      active: true,
      sortable: true,
      activable: true,
    },
    {
      label: 'Délai contractuel',
      key: 'delais_converted',
      active: true,
      sortable: true,
      activable: true,
    },
    {
      label: 'Date de réalisation attendue',
      key: 'expected_realisation_datetime',
      active: true,
      sortable: true,
      activable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Durée total actif (minutes)',
      key: 'active_time',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée total attente (minutes)',
      key: 'hold_time',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée Envoyée (minutes)',
      key: 'time_DEMANDE_ENVOYEE',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée Affecté (minutes)',
      key: 'time_DEMANDE_AFFECTEE',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée Rejetée (minutes)',
      key: 'time_DEMANDE_REJETEE',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée En cours (minutes)',
      key: 'time_DEMANDE_EN_COURS',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée A compléter (minutes)',
      key: 'time_DEMANDE_A_COMPLETER',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée En attente abandon (minutes)',
      key: 'time_DEMANDE_EN_ATTENTE_ABANDON',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée En attente cloture (minutes)',
      key: 'time_DEMANDE_EN_ATTENTE_CLOTURE',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Durée Suspendu (minutes)',
      key: 'time_DEMANDE_SUSPENDUE',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
    {
      label: 'Délai restant',
      key: 'timeLeft',
      active: true,
      sortable: true,
      activable: true,
      filter: 'none',
      type: 'minutesDuration',
    },
    {
      label: 'Canal de contact',
      key: 'communication_channel',
      active: true,
      sortable: true,
      activable: true,
    },
    {
      label: 'Echéance contractuelle',
      key: 'contractual_realisation_datetime',
      active: true,
      sortable: true,
      activable: true,
      filter: 'date',
      type: 'date',
    },
    {
      label: 'Référence interne',
      key: 'internal_reference_number',
      active: true,
      sortable: true,
      activable: true,
      filter: 'number',
    },
  ];

  displayedColumns: String[] = [
    'id', 'created_by', 'concerned_activity', 'contract',
    'demand_object', 'observed_impact', 'location_full_address',
    'location_locality', 'reception_date', 'infos', 'workflow_current_state',
    'realisation_datetime', 'in_charge', 'external_reference_number', 'internal_reference_number', 'action',
  ];

  ngOnInit() {
    const decimalFormatPipe = new DecimalFormatPipe(); // Pour la tableau avec les avis (review)
    this.currentUrl = this.router.url;
    if (this.inputData.indicator.columns) {
      this.displayedColumns = this.inputData.indicator.columns;
    }

    if (this.inputData.indicator.sort) {
      this.customSort = this.inputData.indicator.sort;
    }

    this.demands = this.inputData.demands;
    this.data = this.demands.map((demand: any) => {
      return {
        id: demand.id,
        reviews: demand?.reviews ? decimalFormatPipe.transform(demand?.reviews) : 0,
        created_by: demand.created_by,
        concerned_activity: demand.concerned_activity ? demand.concerned_activity : '',
        contract: demand.contract + ' - ' + demand.contract_label,
        demand_object: demand.demand_object ? demand.demand_object : '',
        observed_impact: demand.observed_impact ? demand.observed_impact : '',
        location_full_address: demand.location_full_address,
        location_locality: demand.location_locality,
        reception_date: demand.transitions === null ? undefined : moment(demand.transitions),
        infos: demand.infos,
        send_date: demand.transitions === null ? undefined : moment(demand.transitions),
        realisation_datetime: demand.realisation_datetime === null ? undefined : moment(demand.realisation_datetime),
        workflow_current_state: getDemandStatusLabel(demand.workflow_current_state_code),
        in_charge: demand.assignations === null ? undefined : demand.assignations,
        external_reference_number: demand.external_reference_number === null ? undefined : demand.external_reference_number,
        description: demand.description,
        workflow_current_state_code: demand.workflow_current_state_code,
        is_assigned_to_me: demand.is_assigned_to_me,
        location_latitude: demand.location_latitude,
        location_longitude: demand.location_longitude,
        delais: demand.delais,
        delais_type: demand.delais !== 0 ? (demand.delais_type !== 'OUV' ? 'Calendaire' : 'Ouvré') : '',
        delais_converted: convertDelai(demand.delais, demand.delais_type),
        receive_date: demand.receive_date ? moment(demand.receive_date) : '',
        close_date: demand.close_date ? moment(demand.close_date) : '',
        last_transition_created_at: moment(demand.last_transition_created_at),
        updated_at: moment(demand.updated_at),
        active_time: demand.active_time,
        hold_time: demand.hold_time,
        communication_channel: demand.communication_channel,
        time_DEMANDE_ENVOYEE: demand.time_DEMANDE_ENVOYEE,
        time_DEMANDE_AFFECTEE: demand.time_DEMANDE_AFFECTEE,
        time_DEMANDE_REJETEE: demand.time_DEMANDE_REJETEE,
        time_DEMANDE_EN_COURS: demand.time_DEMANDE_EN_COURS,
        time_DEMANDE_A_COMPLETER: demand.time_DEMANDE_A_COMPLETER,
        time_DEMANDE_EN_ATTENTE_ABANDON: demand.time_DEMANDE_EN_ATTENTE_ABANDON,
        time_DEMANDE_EN_ATTENTE_CLOTURE: demand.time_DEMANDE_EN_ATTENTE_CLOTURE,
        time_DEMANDE_SUSPENDUE: demand.time_DEMANDE_SUSPENDUE,
        timeLeft: demand.timeLeft,
        contractual_realisation_datetime: this.client_is_setom ? (demand.delais == 0 ? '' : demand.contractual_realisation_datetime) : demand.contractual_realisation_datetime,
      };
    });
  }

  goToDemandPage(event) {
    const demand = this.demands.find((demand) => demand.id === event.selectedItem.id);
    let demandURI = ''
    this.demandService.setTableDemands(event.displayedDataList);
    if (demand.workflow_current_state_code === DemandStateCode.A_ENVOYER) {
      demandURI = '/#/demands/edit/' + demand.id + '?beforeSendMode=true'
    } else {
      demandURI = {
        demandeur: window.location.origin + '/#/demands/' + demand.id,
        exploitant: window.location.origin + '/#/exploitant/demands/' + demand.id,
      }[getUserMode()];
    }
    if (this.currentUrl.includes('indicator') === false) {
      this.router.navigate([`${demandURI}`]);
      this.close();
    } else {
      window.open(`${demandURI}`, 'demand');
    }
  }

  public getIdBackgroundColor(item): string {
    return environment.toggle_feature_id_color && environment.status_colors[item.workflow_current_state_code]
      ? environment.status_colors[item.workflow_current_state_code]
      : 'inherit';
  }

  close() {
    this.dialogRef.close();
  }
}
