<div style="padding: 16px 16px 16px 0;">
  <div style="margin-left: 16px;">
    <app-lineo-breadcrumb [childPageName]="'Mes notifications'"></app-lineo-breadcrumb>
  </div>

  <mat-tab-group (selectedTabChange)="onTabChange($event)" class="notification-tab" animationDuration="0ms" id="skiplink-notification-tab" aria-label="Navigation">
    <!-- Notifications automatiques -->
    <mat-tab label="Notifications automatiques">
      <mat-sidenav-container style="background-color: transparent; margin-top: 25px;">
        <mat-sidenav #sidenav [mode]="'side'" [opened]="isFilterPanelOpen" (closedStart)="isFilterPanelOpen = false"
          style="border-radius: 0 20px 0 20px; box-shadow: 0 2px 10px 0 rgba(0,0,0,0.24); height:fit-content;">
          <ng-container *ngTemplateOutlet="Filter"></ng-container>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="d-flex flex-row">
            <button (click)="isFilterPanelOpen = true" *ngIf="!isFilterPanelOpen" class="filter-button" id="skiplink-notification-filter" aria-label="Filtres">
              <mat-icon>filter_list</mat-icon>
            </button>
            <div style="margin-left: 30px; width: calc(100% - 30px - 34px); min-height: 600px;" id="skiplink-notification-table" aria-label="Liste des notifications">
              <mat-card appearance="outlined" class="card generic-table-div mat-elevation-z8">
                <app-generic-table [data]="receivedAutomaticNotifications" [columns]="automaticNotificationColumns"
                  [displayedColumns]="displayedAutomaticNotificationColumns" [actionOneIcon]="'remove_red_eye'"
                  [isLoading]="isLoading" (onActionOne)="openNotification($event)"
                  (onRowDoubleClick)="goToDemandPage($event)" (onLinkClick)="goToDemandPage($event)">
                </app-generic-table>
              </mat-card>
            </div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-tab>
    <!-- Notifications poussées -->
    <mat-tab label="Notifications ponctuelles">
      <mat-sidenav-container style="background-color: transparent; margin-top: 25px;">
        <mat-sidenav #sidenav [mode]="'side'" [opened]="isFilterPanelOpen" (closedStart)="isFilterPanelOpen = false"
          style="border-radius: 0 20px 0 20px; box-shadow: 0 2px 10px 0 rgba(0,0,0,0.24); height:fit-content;">
          <div>
            <ng-container *ngTemplateOutlet="Filter"></ng-container>
          </div>
        </mat-sidenav>
        <mat-sidenav-content>
          <div class="d-flex flex-row">
            <button (click)="isFilterPanelOpen = true" *ngIf="!isFilterPanelOpen && isFilterEnabled" class="filter-button" id="skiplink-notification-filter" aria-label="Filtres">
              <mat-icon>filter_list</mat-icon>
            </button>

            <div style="margin-left: 30px; width: calc(100% - 30px - 34px); min-height: 500px;" id="skiplink-notification-table" aria-label="Liste des notifications">
              <div class="d-flex justify-content-end" style="margin-bottom: 25px;">
                <button *ngIf="isParam" mat-raised-button class="uppercased mat-primary"
                  (click)="createNewNotification()">
                  <mat-icon class="align-icon">add</mat-icon>
                  Ajouter
                </button>
              </div>
              <div>
                <mat-card appearance="outlined" class="card generic-table-div mat-elevation-z8">
                  <app-generic-table [columns]="notificationColumns" [displayedColumns]="displayedNotificationColumns"
                    [isLoading]="isLoading" [data]="receivedNotifications" [actionOneIcon]="'remove_red_eye'"
                    (onActionOne)="openNotification($event)">
                  </app-generic-table>
                </mat-card>
              </div>
            </div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #Filter>
  <div *ngIf="isFilterEnabled">
    <div class="d-flex justify-content-between filter-panel-title-div" id="skiplink-notification-filter" aria-label="Filtres">
      Filtres
      <button
        (click)="sidenav.toggle()"
        class="btn-sidenav-back"
        aria-label="Cacher filtre"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>
    <div class="d-flex flex-row filter-panel-toggle-div" *ngIf="client_name != 'ileo'">
      Tout le périmètre
      <mat-slide-toggle [(ngModel)]="isContractPerimeterToggle" (change)="
          toggleAllPerimetre();
        " style="margin: 0px 16px;" aria-label="Tout le périmètre"></mat-slide-toggle>
      {{ contract_label }}(s)
    </div>
    <div class="filter-div">
      <mat-form-field class="filter-element" *ngIf="toogle_feature_show_contract_upper_levels">
        <mat-label>Région(s) ({{selectedRegions.length}})</mat-label>
        <mat-select [(value)]="selectedRegions" multiple [disabled]="!isContractPerimeterToggle"
          (selectionChange)="onRegionsChange()">
          <mat-option *ngFor="let region of regions" [value]="region.code">{{region.code + ' - ' + region.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="filter-element" *ngIf="toogle_feature_show_contract_upper_levels">
        <mat-label>Territoire(s) ({{selectedTerritories.length}})</mat-label>
        <mat-select [(value)]="selectedTerritories" multiple [disabled]="!isContractPerimeterToggle"
          (selectionChange)="onTerritoriesChange()">
          <mat-option *ngFor="let territory of territories" [value]="territory.code">
            {{territory.code + ' - ' + territory.label}}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-select #select [virtualScroll]="true" notFoundText="Aucun résultat trouvé"
        placeholder="{{contract_label}}(s)* ({{selectedContracts.length}})" [(ngModel)]="selectedContracts"
        [multiple]="true" [items]="contracts" bindValue="code" appendTo="body" [closeOnSelect]="false" [clearable]="false"
        [searchFn]="onContractSearch" [searchable]="false" dropdownPosition="bottom"
        [disabled]="!isContractPerimeterToggle" (change)="onContractsChange()" (open)="onFieldSelectOpened('contract')"
        class="ng-select-custom filter-element" *ngIf="client_name != 'ileo'">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value">
            <span class="ng-value-label">{{items.length}} Elément(s) sélectionné(s)</span>
          </div>
        </ng-template>
        <ng-template ng-header-tmp>
          <div class="d-flex align-items-center">
            <mat-checkbox [(ngModel)]="allContractsSelected" (change)="selectAllContracts()" color="primary"
              [(indeterminate)]="allContractsSelectedIndeterminate" style="margin-right: 8px;">
            </mat-checkbox>
            <input style="width: 100%; line-height: 24px" type="text" (change)="filterItem($event.target.value)" (input)="select.filter($event.target.value)"/>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-contract="item" let-contract$="item$" let-index="index">
          <div class="pure-material-checkbox" title="{{getContractTooltip(contract)}}" [ngClass]="{'ended-contract': isContractExpired(contract) }">
            <input id="contract-{{index}}" type="checkbox" [ngModel]="contract$.selected"/>
            <span *ngIf="!isContractExpired(contract)">{{ contract.code }} - {{ contract.label }}</span>
            <span *ngIf="isContractExpired(contract)" class="span-end-contract">{{ contract.code }} - {{ contract.label }}&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy' }})</span>
          </div>
        </ng-template>
      </ng-select>

      <mat-divider *ngIf="isNotificationAutomatiqueActive && client_name != 'ileo'" style="margin-bottom: 12px;"></mat-divider>

      <mat-form-field class="filter-element" *ngIf="isNotificationAutomatiqueActive">
        <mat-label>Domaine(s)</mat-label>
        <mat-select [(ngModel)]="selectedDomains" multiple>
          <mat-option *ngFor="let domain of domains" [value]="domain.id">{{domain.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div class="d-flex flex-column" style="padding: 24px">
      <button mat-button class="uppercased mat-primary" (click)="reinitFilter()"
        style="margin-bottom: 16px;border: 1px solid">
        Réinitialiser les filtres
      </button>
      <button mat-raised-button class="uppercased mat-primary" (click)="applyFilter()"
        [disabled]="(selectedContracts.length === 0 && isContractPerimeterToggle) || !isSelectionDifferentOfApplied()">
        Appliquer
      </button>
    </div>
  </div>
</ng-template>
