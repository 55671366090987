<main class="main-view no-child-padding">
  <mat-progress-bar *ngIf="isLoading" mode="indeterminate" class="progressbar"></mat-progress-bar>
  <app-lineo-breadcrumb [childPageName]="'Consultation demande'"></app-lineo-breadcrumb>
  <div class="row-no-margin no-child-padding">
    <h2 class="header title">
      Consultation demande
      <button aria-label="Demande précédente" mat-button (click)="goToPreviousDemand()" [disabled]="!previousDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
        style="font-size: 30px;" class="mat-primary chevron">
        &lt;
      </button>
      <button aria-label="Demande suivante" mat-button (click)="goToNextDemand()" [disabled]="!nextDemand"
        *ngIf="toggle_feature_navigation_demands && (previousDemand || nextDemand)"
        style="font-size: 30px;" class="mat-primary chevron">
        &gt;
      </button>
    </h2>
  </div>
  <div class="row-no-margin no-child-padding">
    <div class="main">
      <app-demand-page-header [demand]="demand"></app-demand-page-header>
      <app-demand-page-content [demand]="demand" (reloadHistory)="reloadHistory.emit(true)" [endReload]="endReload"></app-demand-page-content>
    </div>
    <aside *ngIf="!isSmallScreen">
      <app-exploitant-demand-aside (reloadHistory)="reloadHistory.emit(true)" [demand]="demand"></app-exploitant-demand-aside>
      <app-demand-assignment *ngIf="showAssignment()" [demand]="demand"></app-demand-assignment>
    </aside>
  </div>
  <mat-accordion class="footer-accordion" *ngIf="isSmallScreen">
    <mat-expansion-panel [disabled]="false">
      <mat-expansion-panel-header>
        <app-demand-status-info [demand]="demand" [showDate]="true">
        </app-demand-status-info>
      </mat-expansion-panel-header>
      <div [ngStyle]="{'margin-top': isSmallScreen ? '35px' : '0'}">
        <app-exploitant-demand-aside [demand]="demand"></app-exploitant-demand-aside>
        <app-demand-assignment *ngIf="showAssignment()" [demand]="demand"></app-demand-assignment>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</main>
