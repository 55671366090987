<div *ngIf="!isLoading && reviews?.length">
  <div class="review-main-div">
    <div *ngFor="let review of reviews" class="comment">
      <h4 class="title-h3 size-memo title">
        Note de satisfaction : <span id="rating-number">{{review.rate}} / 4</span>
        <app-rating class="rate" [(rating)]="review.rate" [viewMode]="true"></app-rating>
      </h4>
      <div class="review-info">
        <p class="review-date">Le {{ review.created_at | date: 'dd-MM-yyyy' }} à {{ review.created_at | date: 'HH:mm' }}</p>
        <p class="review-name"> {{review.user?.first_name}} {{review.user?.last_name}}</p>
      </div>
      <p *ngIf="review?.comment" class="review-comment">Commentaire : {{review?.comment}}</p>
    </div>
  </div>
</div>