import { Component, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  constructor(
    private titleService: Title, private httpClient: HttpClient,private renderer: Renderer2
  ) {

    const script = this.renderer.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${environment.google_api_key}`;
    script.onload = () => {
      console.log('Google Map Script loaded successfully');
    };
    this.renderer.appendChild(document.head, script);

    sessionStorage.setItem('isFirstPage', 'true');

    if (environment.client_name === 'national') {
      this.titleService.setTitle(environment.app_name_long);
    } else {
      this.titleService.setTitle(environment.client_to_display+' - '+environment.app_name_long);
    }
    this.changeFavicon();
  }

  changeFavicon() {
    let iconUrl = 'assets/logos/lineo_logo_' + environment.client_name + '.png';
    let link: HTMLLinkElement | null = document.querySelector("link[rel*='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = iconUrl;
  }
}
