import { Component } from "@angular/core";
import { environment } from "@env/environment";
const { version: appVersion } = require("../../../../../package.json");

@Component({
  selector: "app-about-modal",
  templateUrl: "./about-modal.component.html",
  styleUrls: ["./about-modal.component.scss"],
})
export class AboutModalComponent {
  public fiche_memo_link = environment.fiche_memo_link;
  public title: string = "Bienvenue sur la nouvelle version de LINEO";
  public introduction: string =
    "LINEO - version Le Rhône";
  public newStuff = {
    "TABLEAU DE LA PAGE D'ACCUEIL": [
      "Ajout du code contrat dans la vue DEMANDEUR et dans l'export (à ajouter via la roue dentée)"
    ],
    "PAGE DEMANDE": [
      "Ajout, sous le statut de la demande, de la date de dernière modification (en plus de la date de changement de statut)",
      "Possibilité pour le demandeur de laisser une note de satisfaction à la clôture d’une demande (facultatif, 1 à 4 étoiles, notification mail et intégration au mémo exploitant)"
    ],
    "PAGE INDICATEURS": [
      "Ajout d'un indicateur permettant de suivre le nombre de demandes reçues par mois (détail en cliquant)",
      "Ajout d'un indicateur Niveau de satisfaction moyen sur les demandes (démarche Lean, détail en cliquant)"
    ],
    "CHARTE GRAPHIQUE": [
      "Modification de la charte Hubgrade",
      "Agrandissement de toutes les info-bulles (qui apparaissent au survol d'un élément)"
    ],
    "VERSION MOBILE": [
      "Modification de l'aspect des boutons de création de demande et d'accès aux indicateurs, pour faciliter l'accès aux boutons d’action du tableau"
    ]
  };
  public appVersion;
  constructor() {
    this.appVersion = appVersion;
  }

  public keepOrder() {
    /* Keyvalue Pipe sorts by key by default
     this allows us to keep the orignal order */
    return 0;
  }
}
