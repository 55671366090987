import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/guards/authentication.guard';
import { DemandResolver } from './dashboard/demands/components/demand/demand.resolver';
import { UserResolver } from './shared/helpers/user.resolver';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './shared/helpers/global-error-handler';
import { AuthenticationModule } from './authentication/authentication.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DownloadModule } from './download/download.module';
import { DemandsComponent } from './dashboard/demands/demands.component';
import { environment } from '@env/environment';

const routes: Routes = [
    {
        path: 'login',
        loadChildren : () => AuthenticationModule, // To use in Prod mode
    },
    {
        path: '',
        loadChildren : () => DashboardModule,
        canActivate: [AuthGuard],
    },

    {
        path: 'download/demand/:demandid/attachment/:attachmentid',
        loadChildren : () => DownloadModule,
        resolve: { demand: DemandResolver, user: UserResolver },
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            {scrollPositionRestoration: 'enabled', useHash: true},
          ),
    ],
    exports: [RouterModule],
    providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler}, {provide: LocationStrategy, useClass: HashLocationStrategy}],
})
export class AppRoutingModule { }
