import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from '../material/material.module';
import { ErrorsModule } from '@app/shared/errors/errors.module';
import { DemandsTableModule } from './home/components/demands-table/demands-table.module';
import { IndicatorComponent } from './indicators-dashboard/indicator/indicator.component';
import { IndicatorsDashboardComponent } from './indicators-dashboard/indicators-dashboard.component';
import { ChartModule } from 'angular-highcharts';
import { ComponentsModule } from '@app/components/components.module';
import { IndicatorDetailComponent } from '@app/dashboard/indicators-dashboard/indicator-detail/indicator-detail.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { LineoBreadcrumbModule } from '@app/shared/components/lineo-breadcrumb/lineo-breadcrumb.module';
import { NotificationComponent } from './notification/notification.component';
import { IndicatorDetailGraphComponent } from './indicators-dashboard/indicator-detail-graph/indicator-detail-graph.component';
import { PreferencesNotificationComponent } from './preferences-notification/preferences-notification.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExportPdfSelectionComponent } from './indicators-dashboard/export-pdf-selection/export-pdf-selection.component';
import { FooterComponent } from './footer/footer.component';
import { environment } from '@env/environment';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DashboardRoutingModule,
    FormsModule,
    RouterModule,
    ErrorsModule,
    ChartModule,
    ComponentsModule,
    DemandsTableModule,
    ReactiveFormsModule,
    DirectivesModule,
    LineoBreadcrumbModule,
    NgSelectModule,
  ],
  declarations: [
    DashboardComponent,
    HeaderComponent,
    IndicatorsDashboardComponent,
    IndicatorDetailComponent,
    NotificationComponent,
    IndicatorDetailGraphComponent,
    PreferencesNotificationComponent,
    ExportPdfSelectionComponent,
    FooterComponent,
  ]
})
export class DashboardModule {
}
