import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../../material/material.module';
import { DemandPageContentComponent } from './demand-page-content.component';
import { DemandTabComponent } from './demand-tab/demand-tab.component';
import { environment } from '../../../../environments/environment';
import { ComponentsModule } from '../../../components/components.module';
import { TreatmentMonitoringComponent } from './treatment-monitoring/treatment-monitoring.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
} from '@lukana/ng-pick-datetime';
import { DefaultIntl } from '@app/shared/helpers/pick-datetime-intl';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr' },
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ComponentsModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    GoogleMapsModule
  ],
  exports: [
    DemandPageContentComponent,
    DemandTabComponent,
  ],
  declarations: [
    DemandPageContentComponent,
    DemandTabComponent,
    TreatmentMonitoringComponent,
  ],
})
export class DemandPageContentModule { }
