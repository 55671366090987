import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { setUserMode } from '@app/shared/helpers/user-modes-helper';
import { UserPermission } from '@app/shared/helpers/user-permissions';
import { UserType } from '@app/shared/models/user';

@Injectable({
  providedIn: 'root',
})
export class ExploitantGuard implements CanActivate {
  constructor(
    private router: Router,
    private userPermissions: UserPermission,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.userPermissions.hasExploitantRight().then((val) => {
      if (val) {
        setUserMode(UserType.EXPLOITANT);
        return true;
      } else {
        this.router.navigate(['/']);
        return false;
      }
    });
  }
}
