<div *ngIf="!isLoading">
  <div class="additional-info">
    <span><span class="asterisk">**</span> : Champ obligatoire à renseigner lors de l'appui sur ENREGISTRER</span>
    <span><span class="asterisk">*</span> : Champ obligatoire à renseigner lors de l'appui sur ENREGISTRER &
      ENVOYER</span>
  </div>
  <form [formGroup]="demandGroup">
    <!-- Bénéficiaire ------------------------------------------------------- -->
    <fieldset role="group" aria-label="Bénéficiaire">
      <mat-card-header class="first-form-header">
        <mat-card-title>
          <h3>Bénéficiaire</h3>
        </mat-card-title>
      </mat-card-header>
      <div class="row">
        <div>
          <h6 style="margin-right: 15px;float: left;line-height: 28px;">
            Etes-vous le bénéficiaire de cette demande ?
          </h6>
          <span>Non
          <mat-slide-toggle checked="true" formControlName="is_creator_beneficiary" (change)="toggleBeneficiary($event)"
            [disabled]="editionMode" aria-label="Etes-vous le bénéficiaire de cette demande ?">Oui
          </mat-slide-toggle>
        </span>
        </div>

        <div class="rgpd-block">
          <mat-icon *ngIf="!isBenificiaryChecked" class="rgpd-icon">lock</mat-icon>
          <i *ngIf="!isBenificiaryChecked" class="rgpd-info rgpd-info-first">
            Assurez-vous que le bénéficiaire a bien été informé du traitement de ses données personnelles
          </i>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>NOM <span class="asterisk">*</span> <span class="asterisk visually-hidden" >*</span></mat-label>
            <input matInput class="uppercased" formControlName="beneficiary_last_name" autocomplete="family-name" aria-describedby="last_name_error">
          </mat-form-field>
          <mat-error
            id="last_name_error"
            *ngIf="!demandGroup.get('beneficiary_last_name')?.disabled && !demandGroup.get('beneficiary_last_name')?.valid && isbtnSaveClicked">
            Nom est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>Prénom <span class="asterisk">*</span><span class="asterisk visually-hidden" >*</span></mat-label>
            <input matInput formControlName="beneficiary_first_name" autocomplete="given-name" aria-describedby="first_name_error">
          </mat-form-field>
          <mat-error
            id="first_name_error"
            *ngIf="!demandGroup.get('beneficiary_first_name')?.disabled && !demandGroup.get('beneficiary_first_name')?.valid && isbtnSaveClicked">
            Prénom est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>Téléphone</mat-label>
            <input
              matInput
              type="tel"
              placeholder="Téléphone"
              maxlength="18"
              formControlName="beneficiary_phone"
              autocomplete="tel"
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>Email</mat-label>
            <input
              matInput
              placeholder="Email"
              formControlName="beneficiary_email"
              autocomplete="email"
            >
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Type de bénéficiaire <span class="asterisk">*</span><span class="asterisk visually-hidden" >*</span></mat-label>
            <mat-select formControlName="beneficiary_type" disableOptionCentering panelClass="dropdown-position" aria-describedby="beneficiary_type_error">
              <mat-option *ngFor="let b_type of _beneficiaryTypes" [value]="b_type.id" >
                {{b_type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            id="beneficiary_type_error"
            *ngIf="!demandGroup.get('beneficiary_type')?.disabled && !demandGroup.get('beneficiary_type')?.valid && isbtnSaveClicked">
            Type de bénéficiaire est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4" *ngIf="toggle_feature_communication_channel">
          <mat-form-field>
            <mat-label>Canal de contact <span class="asterisk">*</span><span class="asterisk visually-hidden" >*</span></mat-label>
            <mat-select formControlName="communication_channel" disableOptionCentering panelClass="dropdown-position"
                        [(value)]="communication_channel" aria-describedby="communication_channel_error">
              <mat-option value="Application">Application</mat-option>
              <mat-option value="Bordereau">Bordereau</mat-option>
              <mat-option value="Courrier">Courrier</mat-option>
              <mat-option value="Echange_verbal">Echange verbal</mat-option>
              <mat-option value="Fax">Fax</mat-option>
              <mat-option value="Mail">Mail</mat-option>
              <mat-option value="Téléphone">Téléphone</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            id="communication_channel_error"
            *ngIf="!demandGroup.get('communication_channel')?.disabled && !demandGroup.get('communication_channel')?.valid && isbtnSaveClicked">
            Canal de contact est obligatoire
          </mat-error>
        </div>
      </div>
    </fieldset>
    <!-- Localisation ------------------------------------------------------- -->
    <fieldset role="group" aria-label="Localisation de la demande">
      <mat-card-header>
        <mat-card-title>
          <h3>Localisation de la demande </h3>
        </mat-card-title>
      </mat-card-header>
      <div class="form__localisation">
      <div>
        <div class="pl15 pr15">
          <mat-form-field class="example-full-width">
            <mat-label>Adresse <span class="asterisk">*</span><span class="asterisk visually-hidden" >*</span></mat-label>
            <input
              matInput
              id="adresse-autocomplete"
              (change)="resetLocalisation()"
              formControlName="location_full_address"
              autocomplete="address-line1"
              aria-describedby="location_address_error"
            >
          </mat-form-field>
          <mat-error
            id="location_address_error"
            *ngIf="!demandGroup.get('location_full_address')?.disabled && !demandGroup.get('location_full_address')?.valid && isbtnSaveClicked">
            Adresse est obligatoire
          </mat-error>
        </div>
        <div class="pl15 pr15 hide-mobile">
          <ng-template #emplacementInput>
            <mat-form-field>
              <mat-select  aria-label="emplacement" placeholder="Emplacement" formControlName="location_emplacement" disableOptionCentering
                panelClass="dropdown-position">
                <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
                <mat-option *ngFor="let e_type of emplacementTypes" [value]="e_type.id">
                  {{e_type.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
          <ng-content *ngTemplateOutlet="emplacementInput"></ng-content>
        </div>
        <div class="row readonly-input hide-mobile">
          <div class="col-sm-3">
            <mat-form-field class="example-full-width">
              <mat-label>N° de voie</mat-label>
              <input tabindex="-1" matInput class="text-color" readonly formControlName="location_street_number" matTooltip="N° de voie" >
            </mat-form-field>
          </div>
          <div class="col-sm-3">
            <mat-form-field class="example-full-width">
              <mat-label>Type de voie</mat-label>
              <input tabindex="-1" matInput class="text-color" readonly formControlName="location_street_type" >
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field class="example-full-width">
              <mat-label>Voie</mat-label>
              <input tabindex="-1" matInput class="text-color" readonly formControlName="location_route" autocomplete="street-address">
            </mat-form-field>
          </div>
        </div>
        <div class="row readonly-input hide-mobile">
          <div class="col-sm-4">
            <mat-form-field class="example-full-width">
              <mat-label>Code postal</mat-label>
              <input tabindex="-1" matInput readonly class="text-color" formControlName="location_postal_code" matTooltip="Code Postal" autocomplete="postal-code">
            </mat-form-field>
          </div>
          <div class="col-sm-8">
            <mat-form-field class="example-full-width">
              <mat-label>Commune</mat-label>
              <input tabindex="-1" matInput readonly class="text-color" formControlName="location_locality" autocomplete="address-level2">
            </mat-form-field>
          </div>
        </div>
      </div>  
      <div style="padding-top: 15px;">        
        <google-map class="map"
          (mapInitialized)="onMapReady()"
          (mapClick)="addMarker($event)"
          height="300px"
          width="100%"
          [center]="center"
          [zoom]="zoom"
          [options]="mapOptions">
          <button class="geolocate" *ngIf="showGeolocateButton" mat-icon-button id="geolocation" type="button" (click)="geolocate()">
            <mat-icon>gps_fixed</mat-icon>
          </button>
          <map-marker [position]="markerPosition" [visible]="markerPosition != undefined"></map-marker>
          @if(displayContractShape) {
            <map-polyline [path]="PolylineVerticesPX" [options]="PolylineOptionsPX"/>
            <map-polyline [path]="PolylineVerticesT1" [options]="PolylineOptionsClassic"/>
            <map-polyline [path]="PolylineVerticesT2" [options]="PolylineOptionsClassic"/>
            <map-polyline [path]="PolylineVerticesT3" [options]="PolylineOptionsClassic"/>
            <map-polyline [path]="PolylineVerticesT4" [options]="PolylineOptionsClassic"/>
            <map-polyline [path]="PolylineVerticesT5" [options]="PolylineOptionsClassic"/>
          }
        </google-map>
      </div>
      <div class="pl15 pr15 hide-desktop form__localisation__emplacement--mobile">
        <ng-content *ngTemplateOutlet="emplacementInput"></ng-content>
      </div>
    </div>
    </fieldset>
    <!-- Demande ------------------------------------------------------------ -->
    <fieldset role="group" aria-label="Demande">
      <mat-card-header>
        <mat-card-title>
          <h3>Demande</h3>
        </mat-card-title>
      </mat-card-header>
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>{{activity_label}} <span class="asterisk">*</span><span class="asterisk visually-hidden" >*</span></mat-label>
            <mat-select formControlName="concerned_activity" disableOptionCentering panelClass="dropdown-position" aria-describedby="concerned_activity_error">
              <mat-option *ngFor="let a_type of _activityTypes" [value]="a_type.id">
                {{a_type.code}} - {{a_type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            id="concerned_activity_error"
            *ngIf="!demandGroup.get('concerned_activity')?.valid && isbtnSaveClicked">
            {{activity_label}} est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-8">
          <div class="ng-select-field">
            <label for="contracts" class="visually-hidden">
              {{contract_label}}
              <span class="asterisk visually-hidden" >*</span>
              <span class="asterisk visually-hidden" >*</span>
            </label>
            <ng-select [virtualScroll]="true" notFoundText="Aucun résultat trouvé" placeholder="{{contract_label}}"
                       formControlName="contract" clearAllText="Vider" [searchFn]="onContractSearch" [items]="availableContracts"
                       bindValue="code" bindLabel="name" appendTo="body" aria-describedby="contract_error_1 contract_error_2"
                       labelForId="contracts" (change)="onContractChange($event)"
            >
              <ng-template ng-label-tmp let-contract="item" let-clear="clear">
                <div class="ng-value-label" [ngClass]="{'ended-contract': isContractExpired(contract) }">
                  {{ contract.code }} - {{ contract.label }}
                  <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy'
                    }})</span>
                </div>
              </ng-template>
              <ng-template ng-option-tmp let-contract="item">
                <div [title]="getContractTooltip(contract)" [ngClass]="{'ended-contract': isContractExpired(contract) }">
                  {{ contract.code }} - {{ contract.label }}
                  <span *ngIf="isContractExpired(contract)">&nbsp;({{ contract.date_fin_exploitation | date: 'dd/MM/yyyy'
                    }})</span>
                </div>
              </ng-template>
            </ng-select>
            <mat-error
              id="contract_error_1"
              *ngIf="!demandGroup.get('contract')?.value && isbtnSaveClicked">
              {{contract_label}} est obligatoire
            </mat-error>
            <mat-error
              id="contract_error_2"
              *ngIf="toogle_feature_contract_from_zone && availableContracts.length === 0">
              L'adresse saisie ne se trouve pas dans votre périmètre utilisateur (contactez votre administrateur)
            </mat-error>
          </div>
        </div>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Objet de la demande</mat-label>
            <mat-select *ngIf="!isSmallScreen" formControlName="demand_object" disableOptionCentering
                        panelClass="dropdown-position" aria-describedby="demand_object_error">
              <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
              <mat-option *ngFor="let ob_type of objectTypes$ | async" [value]="ob_type.id" matTooltip="{{ob_type.label}}">
                {{ob_type.label}}
              </mat-option>
            </mat-select>
            <mat-select *ngIf="isSmallScreen" formControlName="demand_object" disableOptionCentering
                        panelClass="dropdown-position" aria-describedby="demand_object_error">
              <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
              <mat-option *ngFor="let ob_type of objectTypes$ | async" [value]="ob_type.id">
                {{ob_type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            id="demand_object_error"
            *ngIf="!demandGroup.get('demand_object')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            Objet est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Impact de la nuisance observée</mat-label>
            <mat-select formControlName="observed_impact" disableOptionCentering panelClass="dropdown-position" aria-describedby="observed_impact_error">
              <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
              <mat-option [ngClass]="{'select-without-choice': (i_type.id == 1 && isListWithout) }" *ngFor="let i_type of impactTypes$ | async" [value]="i_type.id" [disabled]="i_type.id == 1 && isListWithout">
                <div class="__span_code_{{i_type.code}}">{{i_type.label}}</div>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            id="observed_impact_error"
            *ngIf="!demandGroup.get('observed_impact')?.valid && isbtnSaveClicked && !withoutObjectSelected && isTryingToSendAndSave">
            Impact est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Type d'ouvrage</mat-label>
            <mat-select formControlName="ouvrage_type" disableOptionCentering
                        panelClass="dropdown-position">
              <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
              <mat-option *ngFor="let o_type of ouvrageTypes$ | async" [value]="o_type.id">
                {{o_type.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-4" *ngIf="demandGroup.get('asset_id')?.value">
          <mat-form-field class="example-full-width">
            <mat-label>Identifiant Élément/Patrimoine</mat-label>
            <input matInput formControlName="asset_id" readonly>
          </mat-form-field>
        </div>
        <div class="col-sm-8" *ngIf="demandGroup.get('asset_label')?.value">
          <mat-form-field class="example-full-width">
            <mat-label>Libellé Élément/Patrimoine</mat-label>
            <input matInput formControlName="asset_label" readonly>
          </mat-form-field>
        </div>
        
        <div class="col-sm-4" *ngIf="showBranching">
          <mat-form-field>
            <mat-label>Nombre de branchements ?</mat-label>
            <input matInput type="number" placeholder="Nombre de branchements ?" autocomplete="off"
                   formControlName="branching" min="1" max="300" required
                   aria-describedby="branching_error_1 branching_error_2 branching_error_3"
            >
          </mat-form-field>
          <mat-error
            id="branching_error_1"
            *ngIf="demandGroup.get('branching')?.errors?.required">
            Nombre de branchements est obligatoire
          </mat-error>
          <mat-error
            id="branching_error_2"
            *ngIf="demandGroup.get('branching')?.errors?.min">
            Le nombre de branchements minimal est de 1
          </mat-error>
          <mat-error
            id="branching_error_3"
            *ngIf="demandGroup.get('branching')?.errors?.max">
            Le nombre de branchements maximal est de 300
          </mat-error>
        </div>
      </div>
    </fieldset>
    <!-- Document d'urbanisme ------------------------------------------------------------ -->
    <fieldset role="group" aria-label="Document d'urbanisme">
      <mat-card-header *ngIf="showBlockUrba">
        <mat-card-title>
          <h3>Document d'urbanisme</h3>
        </mat-card-title>
      </mat-card-header>
      <div class="row" *ngIf="showBlockUrba">
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Type d'avis</mat-label>
            <mat-select formControlName="urba_type_doc" disableOptionCentering panelClass="dropdown-position" aria-describedby="urba_type_doc_error">
              <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
              <mat-option *ngFor="let urba_type of urbaDocType" [value]="urba_type">
                {{urba_type}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            id="urba_type_doc_error"
            *ngIf="showBlockUrba && !demandGroup.get('urba_type_doc')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            Type d'avis est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>N° de l'avis</mat-label>
            <input matInput #avis_nb formControlName="urba_avis_nb" matTooltip="N° d'avis" maxlength="30" aria-describedby="urba_avis_nb_error">
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{avis_nb.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 30
            </mat-hint>
          </mat-form-field>
          <mat-error
            id="urba_avis_nb_error"
            *ngIf="showBlockUrba && !demandGroup.get('urba_avis_nb')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            N° de l'avis est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="realisation_datetime_wrap align_urba">
            <mat-label>Date de l'avis</mat-label>
            <mat-icon class="calendar-trigger-icon-urba l-icon" [owlDateTimeTrigger]="dt2">calendar_today
            </mat-icon>
            <mat-icon *ngIf="demandGroup.controls['urba_avis_date'].value" class="l-icon clear-date-urba"
                      (click)="demandGroup.controls['urba_avis_date'].reset()" title="Vider">clear
            </mat-icon>
            <input matInput readonly formControlName="urba_avis_date" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" aria-describedby="urba_avis_date_error">
            <owl-date-time #dt2 [firstDayOfWeek]="1" [pickerType]="'calendar'"></owl-date-time>
          </mat-form-field>
          <mat-error
            id="urba_avis_date_error"
            *ngIf="showBlockUrba && !demandGroup.get('urba_avis_date')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            Date de l'avis est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>Section</mat-label>
            <input matInput #section_nb formControlName="urba_section" matTooltip="Section" maxlength="30" aria-describedby="urba_section_error">
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{section_nb.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 30
            </mat-hint>
          </mat-form-field>
          <mat-error
            id="urba_section_error"
            *ngIf="showBlockUrba && !demandGroup.get('urba_section')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            Section est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field class="example-full-width">
            <mat-label>N° de parcelle</mat-label>
            <input matInput #parcelle_nb formControlName="urba_parcelle_nb" matTooltip="N° de parcelle" maxlength="30" aria-describedby="urba_parcelle_nb_error">
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{parcelle_nb.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 30
            </mat-hint>
          </mat-form-field>
          <mat-error
            id="urba_parcelle_nb_error"
            *ngIf="showBlockUrba && !demandGroup.get('urba_parcelle_nb')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            N° de parcelle est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Motif de l'avis</mat-label>
            <mat-select formControlName="urba_motif" disableOptionCentering panelClass="dropdown-position">
              <mat-option class="select-empty-choice" [value]="null">Aucun</mat-option>
              <mat-option *ngFor="let urba_motif of urbaDocMotif" [value]="urba_motif">
                {{urba_motif}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="demandGroup.get('urba_motif').value === 'Autre'">
            <mat-label>Autre</mat-label>
            <input matInput #other_nb formControlName="urba_motif_bis" matTooltip="Autre" maxlength="30"
                   aria-describedby="urba_motif_bis_error_1 urba_motif_bis_error_2 urba_motif_bis_error_3">
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{other_nb.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 30
            </mat-hint>
          </mat-form-field>
          <mat-error
            id="urba_motif_bis_error_1"
            *ngIf="showBlockUrba && !demandGroup.get('urba_motif')?.valid && isbtnSaveClicked && isTryingToSendAndSave">
            Motif de l'avis est obligatoire
          </mat-error>
          <mat-error
              id="urba_motif_bis_error_2"
              *ngIf="showBlockUrba && demandGroup.get('urba_motif').value === 'Autre' &&
              !demandGroup.get('urba_motif_bis')?.valid &&
              demandGroup.get('urba_motif_bis').value?.length <= 30 && isbtnSaveClicked">
            Motif 'Autre' est obligatoire
          </mat-error>
          <mat-error
              id="urba_motif_bis_error_3"
              *ngIf="showBlockUrba && demandGroup.get('urba_motif').value === 'Autre' &&
              demandGroup.get('urba_motif_bis').value !== null &&
              demandGroup.get('urba_motif_bis').value.length > 30 && isbtnSaveClicked">
            Motif 'Autre' ne doit pas dépasser 30 caractères
          </mat-error>
        </div>
      </div>
    </fieldset>
    <!-- Complement d'information ------------------------------------------  -->
    <fieldset role="group" aria-label="Compléments d'information">
      <mat-card-header>
        <mat-card-title>
          <h3>Compléments d'information</h3>
        </mat-card-title>
        <div class="rgpd-block" style="margin-left: 5px;">
          <mat-icon class="rgpd-icon">lock</mat-icon>
          <i class="rgpd-info rgpd-info-first">
            Évitez les informations nominatives et/ou personnelles
          </i>
        </div>
      </mat-card-header>
      <div class="row pl15 pr15">
        <div class="col-sm-12 textarea-wrap">
          <mat-form-field class="example-full-width">
            <mat-label>Description <span class="asterisk">*</span></mat-label>
            <textarea class="description-aria" #description matInput placeholder="Description *"
                      formControlName="description" maxlength="3000" aria-describedby="description_info_error">
            </textarea>
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{description.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 3000
            </mat-hint>
          </mat-form-field>
          <mat-error
            id="description_info_error"
            *ngIf="!demandGroup.get('description')?.disabled && !demandGroup.get('description')?.valid && isbtnSaveClicked">
            Description est obligatoire
          </mat-error>
        </div>
        <div class="col-sm-12 textarea-wrap">
          <mat-form-field class="example-full-width">
            <mat-label>Actions attendues</mat-label>
            <textarea class="actions-aria" #actions matInput placeholder="Actions attendues" formControlName="actions_to_go"
                      maxlength="3000"></textarea>
            <mat-hint align="end">
              <span class="visually-hidden">Compteur de caractères</span>
              {{actions.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 3000
            </mat-hint>
          </mat-form-field>
        </div>
      </div>
    </fieldset>

    <div class="row mt20 pl15"
      *ngIf="mode !== 'exploitant' && contractual_realisation_datetime && client_name === 'setom'"
      style="margin-bottom: -35px;margin-top: 0 !important;">
      <div style="display: flex; flex-direction: row;">
        <span *ngIf="demandGroup?.get('delais').value && demandGroup?.get('delais').value > 0">Echéance contractuelle :
          {{ contractual_realisation_datetime | date: 'dd/MM/yyyy HH:mm' }}</span>
        <span *ngIf="demandGroup?.get('delais').value && demandGroup?.get('delais').value === 0">Aucune échéance
          contractuelle</span>
      </div>
    </div>

    <div class="row mt20">
      <div class="col-sm-6 pr15">
        <mat-form-field class="realisation_datetime_wrap">
          <button
            matSuffix
            class="clickable btn-realisation_datetime"
            aria-label="Vider l'horaire"
            [disabled]="demandGroup.controls['realisation_datetime'].disabled"
            *ngIf="demandGroup.controls['realisation_datetime'].value"
            (click)="demandGroup.controls['realisation_datetime'].reset()"
          >
            <mat-icon>clear</mat-icon>
          </button>
          <button
            matSuffix
            aria-label="calendrier"
            class="clickable btn-realisation_datetime"
            [owlDateTimeTrigger]="dt2"
          >
            <mat-icon>calendar_today</mat-icon>
          </button>
          <button
            matSuffix
            matTooltip="Délai soumis à l’analyse de {{client_to_display}}"
            class="clickable btn-realisation_datetime"
          >
            <mat-icon>info</mat-icon>
          </button>
          <mat-label>Échéance souhaitée</mat-label>
          <input matInput placeholder="Échéance souhaitée" formControlName="realisation_datetime"
                 [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" aria-describedby="realisation_datetime_error">
          <owl-date-time [startAt]="min" #dt2 [firstDayOfWeek]="1"></owl-date-time>
        </mat-form-field>
        <mat-error
            id="realisation_datetime_error"
            *ngIf="!demandGroup.get('realisation_datetime')?.valid
            && !demandGroup.get('realisation_datetime')?.disabled
            && isbtnSaveClicked">
          La date doit être dans le futur
        </mat-error>
      </div>
      <div class="col-sm-6 ref-ext">
        <mat-form-field class="example-full-width">
          <mat-label>N° Référence externe</mat-label>
          <input matInput type="text" #ext_ref placeholder="N° Référence externe"
            formControlName="external_reference_number" maxlength="30">
          <mat-hint align="end">
            <span class="visually-hidden">Compteur de caractères</span>
            {{ext_ref.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 30
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row pl15"
      *ngIf="(toggle_feature_send_ilotier) && (client_name !== 'national')">
      <div class="col-sm-9 pr15 vertial-center">
        <mat-checkbox formControlName="send_ilotier" *ngIf="toggle_feature_send_ilotier">A été transmis à un îlotier
        </mat-checkbox>
      </div>
      <!-- HIDE (HVLI-971)
      <div class="col-sm-9 ref-int">
        <mat-form-field class="example-full-width" *ngIf="toggle_feature_internal_reference && mode === 'exploitant'">
          <input matInput #int_ref placeholder="N° Référence interne" formControlName="internal_reference_number"
            maxlength="20">
          <mat-hint align="end">
            <span class="visually-hidden">Compteur de caractères</span>
            {{int_ref.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 20
          </mat-hint>
        </mat-form-field>
      </div>
      -->
    </div>
    <!-- HIDE (HVLI-971)
    <div class="row pl15" *ngIf="toggle_feature_internal_comment && mode === 'exploitant' && client_name !== 'national'">
      <div class="col-sm-12 pr15">
        <mat-form-field class="example-full-width">
          <mat-label>Commentaire interne
            <mat-icon class="rgpd-icon rgpd-icon-spacing">lock</mat-icon>
            <i class="rgpd-info">Évitez les informations nominatives et/ou personnelles</i>
          </mat-label>
          <textarea #int_comment matInput cdkTextareaAutosize placeholder="Commentaire interne" class="actions-aria"
            formControlName="internal_comment" maxlength="3000">
              </textarea>
          <mat-hint align="end">
            <span class="visually-hidden">Compteur de caractères</span>
            {{int_comment.value.length}} <span aria-hidden="true">/</span><span class="visually-hidden">sur</span> 3000
          </mat-hint>
        </mat-form-field>
      </div>
    </div>
    -->
    <div class="row lpad div-app-upload">
      <app-upload class="full-width" [demand]="_demand" [files]="_demand.attachments"
        (filesChanged)="linkAttachments($event)">
      </app-upload>
    </div>
  </form>
  <mat-card-actions>
    <button *ngIf="!editionMode && !newDemand" class="btn-form-delete full-width-btn-mobile mat-primary"
      mat-stroked-button (click)="delete()">
      SUPPRIMER
    </button>
    <button class="btn-form-delete full-width-btn-mobile mat-primary" mat-stroked-button (click)="cancelRedirect()">
      ANNULER
    </button>
    <button *ngIf="!editionMode" class="btn-save full-width-btn-mobile mat-primary" mat-stroked-button
      (click)="save(false)" [hv360-loading]="saveBtnLoading">
      ENREGISTRER
    </button>
    <button class="uppercased btn-form-send full-width-btn-mobile mat-primary" mat-raised-button (click)="save(true)"
      [hv360-loading]="saveSendBtnLoading">
      <div>{{ editionMode ? 'METTRE À JOUR' : 'ENREGISTRER & ENVOYER' }}</div>
    </button>
  </mat-card-actions>

</div>
<div *ngIf="isLoading" class="loader">
  <mat-spinner diameter="100" *ngIf="isLoading"></mat-spinner>
</div>
