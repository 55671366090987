<mat-card appearance="outlined" class="mixed-round-border">
  <div>
    <mat-card-title class="bold">
      Demande n° {{ demand?.id }} <span class="dmd-page-grey"> - {{contract_label}} {{ demand?.contract }} {{client_name !== 'national' ? ' - ' + demand?.contract_label : ''}} </span>
    </mat-card-title>

    <mat-card-content>
      <p *ngIf="demand && demand.created_by">Demandeur : {{ demand.created_by.email_contact && demand.created_by.email_contact !== null ? demand.created_by.email_contact : demand.created_by.email }}</p>
      <div *ngIf="client_name === 'setom'">
        <p *ngIf="demand.delais > 0">Échéance contractuelle :
          <span [ngClass]="{'urgent-demand': isCloseToContractualEcheanceDate()}">
            {{ contractual_realisation_datetime  | date: 'dd/MM/yy HH:mm' }}
          </span>
        </p>
        <p *ngIf="demand.delais === 0">Aucune échéance contractuelle</p>
      </div>
      <div *ngIf=demand?.realisation_datetime>
        <p>Échéance souhaitée : <span [ngClass]="{'urgent-demand': isCloseToEcheanceDate()}">
        {{ demand?.realisation_datetime  | date: 'dd/MM/yy' }}
      </span></p>
      </div>
    </mat-card-content>
  </div>

  <div class="dmd-impact bold">
    Impact nuisance<span class="dmd-page-grey"> : {{ demand?.observed_impact?.label }}</span>
    <mat-progress-bar mode="determinate" [value]="this.impactProgressBarValue[demand?.observed_impact?.code]"></mat-progress-bar>
  </div>
</mat-card>
