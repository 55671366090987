<h2 class="bold">{{ title }}</h2>
<mat-dialog-content class="mat-typography text-justify">
  <p class="bold">{{ introduction }}</p>

  <!-- <p>Lineo évolue pour faciliter la prise en charge des demandes par les exploitants.</p> -->

  <ng-container *ngFor="let news of newStuff | keyvalue: keepOrder">
    <h3 class="uppercased" style="margin: 0 0 0px;">{{ news.key }}</h3>
    <ul style="margin-bottom: 0.5rem; text-align: justify;">
      <li *ngFor="let n of news.value" [innerHTML]="n"></li>
    </ul>
  </ng-container>
  <br>
  <h3>Pour plus de détails, voir la <a style="text-decoration: underline;" aria-label="Fiche mémo" href="{{fiche_memo_link}}" target="_blank">Fiche Mémo</a></h3>

</mat-dialog-content>
<mat-dialog-actions>
  <p class="appVersion">Version {{appVersion}}</p>
  <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial class="uppercased mat-primary">
    Je découvre
  </button>
</mat-dialog-actions>
