<ng-template #editIcon>
  <li>
    <button (click)="openAssignmentsDialog()">
      <mat-icon>edit</mat-icon>
    </button>
  </li>
</ng-template>

<mat-card appearance="outlined" class="assignments mixed-round-border ml-25">
  <mat-card-content>
    <h3 class="title-assignment">Référent</h3>
    <ul class="assignments__list-container badge-ul">
      <ng-content *ngTemplateOutlet="!readOnly() && editIcon">
      </ng-content>
      <li *ngIf="responsable">
        <app-user-badge [user]="responsable"></app-user-badge>
      </li>
    </ul>
    <h3 class="title-assignment">{{ 'Intervenant' + (intervenants?.length > 1 ? 's' : '')}}</h3>
    <ul class="assignments__list-container badge-ul">
      <ng-content *ngTemplateOutlet="!readOnly() && editIcon">
      </ng-content>
      <li *ngFor="let intervenant of intervenants">
        <app-user-badge [user]="intervenant"></app-user-badge>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
