import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDirective } from './loading.directive';
import { MaterialModule } from '@app/material/material.module';
import { MatSpinner } from '@angular/material/progress-spinner';
@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [LoadingDirective],
  exports: [LoadingDirective]
})
export class DirectivesModule {}
