import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { DemandService } from '@app/shared/services/demand/demand.service';
import {environment} from "@env/environment";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private demandService: DemandService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.MOCK_AUTH){
      return true;
    }
    console.log('canActivate');
    if (localStorage.getItem('token')) {
      if (sessionStorage.getItem('returnUrl')) {
        console.log('gotoStartPage on canActivate');
        this.demandService.gotoStartPage();
        return false;
      }
      return true;
    }
    if (state.url) {
      this.demandService.saveStartPageUrl();
    }
    this.router.navigate(['/login']);
    return false;
  }
}
