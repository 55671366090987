import {
  Directive,
  ElementRef,
  ComponentFactoryResolver,
  Renderer2,
  ViewContainerRef,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  HostListener,
} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hv360-loading]',
})
export class LoadingDirective implements OnInit, OnChanges {
  constructor(
    private el: ElementRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
  ) {}
  @Input('hv360-loading') isLoading: boolean;
  spinner: MatProgressSpinner;
  layer;
  ngOnInit(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      MatProgressSpinner,
    );
    const componentRef = this.viewContainerRef.createComponent(factory);
    this.layer = this.renderer.createElement('div');
    this.spinner = componentRef.instance;
    this.spinner.mode = 'indeterminate';
    this.spinner.strokeWidth = 3;
    this.spinner.diameter = 24;
    this.renderer.addClass(this.layer, 'hv360-loading-layer');
    this.renderer.appendChild(
      this.layer,
      this.spinner._elementRef.nativeElement,
    );
    if (this.isLoading) {
      this.showSpinner();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.spinner) {
      // Don't remove the false/true nor make an else
      // Otherwise even a [hv360-loading]="***"
      // will activate the loading state
      if (changes.isLoading.currentValue === true) {
        this.showSpinner();
      }
      if (changes.isLoading.currentValue === false) {
        this.hideSpinner();
      }
    }
  }

  showSpinner() {
    this.renderer.setStyle(this.el.nativeElement, 'color', 'transparent');
    this.renderer.appendChild(this.el.nativeElement, this.layer);
  }
  hideSpinner() {
    this.renderer.removeStyle(this.el.nativeElement, 'color');
    this.renderer.removeChild(this.el.nativeElement, this.layer);
  }

  @HostListener('click', ['$event.target'])
  public onClick(event): void {
    if (this.isLoading) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
