import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { User } from '@app/shared/models/user';
import { AuthenticationService } from '@app/authentication/authentication.service';
import { getUserHome } from '@app/shared/helpers/user-modes-helper';
import { DemandService } from '@app/shared/services/demand/demand.service';

declare const gapi: any;

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {

  public email: string;
  public error: string;
  public client: string;
  public module_name: string;
  public url: string;

  googleAuth;
  authenticationTypes = environment.authentication_types;
  toggle_feature_publik_connexion: boolean = environment.toggle_feature_publik_connexion;
  url_logout: string = environment.KEYCLOAK_LOGOUT;
  client_id: string = environment.CLIENT_ID_KEYCLOAK;
  origin: string = window.location.origin;
  showPublik: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private zone: NgZone,
    private router: Router,
    private authenticationService: AuthenticationService,
    private demandService: DemandService,
  ) {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: environment.client_id,
        cookiepolicy: "single_host_origin",
        scope: 'profile email'
      })
        .then(() => {
          this.zone.run(() => {
            this.googleAuth = gapi.auth2.getAuthInstance();
            // const googleUser = this.googleAuth.currentUser.get();

            // if (googleUser && googleUser.getId()) {
            //   this.afterGoogleLogin(googleUser);
            // }
          });
        });
    });
  }

  ngOnInit() {
    this.route
      .queryParams
      .subscribe((params) => {
        this.email = params['email'] || 'Inconnu';
        this.error = params['error']
      });
    this.client = environment.client_name;
    if (this.toggle_feature_publik_connexion === true && this.error === 'USER_DOES_NOT_EXIST' && this.client === "setom") {
      this.showPublik = true;
    }
    this.module_name = environment.app_name_long;
    this.url = window.location.origin;
  }

  authenticationTypeOk(authTypeToCheck) {
    return this.authenticationTypes.indexOf(authTypeToCheck) !== -1;
  }

  googleSignIn() {
    this.googleAuth.signIn({
      // Show the prompt
      prompt: "consent"
    })
      .then(googleUser => {
        this.zone.run(() => {
          this.afterGoogleLogin(googleUser);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  publikSignIn() {
    this.authenticationService.loginPublik()
  }

  keycloakSignIn() {
    this.authenticationService.loginPublik()
  }

  afterGoogleLogin(googleUser) {
    this.authenticationService.setToken(googleUser.getAuthResponse().id_token);

    this.authenticationService.getUser().then((user: User) => {
      const profile = googleUser.getBasicProfile();
      /*user.firstname = profile.getGivenName();
      user.lastname = profile.getFamilyName();
      user.avatar = profile.getImageUrl();*/
      user.profile_picture = profile.getImageUrl();
      this.authenticationService.setUserData(user);
      this.demandService.resetMemoryData();
      console.log('gotoStartPage After afterGoogleLogin on not authorized page');
      this.demandService.gotoStartPage();
    },
      (err) => {
        this.router.navigate(['/not-authorized']);
      });
  }
}
