import { AuthenticationService } from '@app/authentication/authentication.service';
import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { User, UserType } from '@app/shared/models/user';
import Apps from '../../../assets/hypervision-apps.json';
import {
  getUserHome,
  getUserMode,
  setUserMode,
} from '@app/shared/helpers/user-modes-helper';
import { RgpdModalComponent } from '@app/components/modals/rgpd-modal/rgpd-modal.component';
import { AboutModalComponent } from '@app/components/modals/about-modal/about-modal.component';

import { CACHE_KEY } from '@app/components/modals/about-modal/about-modal-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { UserPermission } from '@app/shared/helpers/user-permissions';
import { AppNotification } from '@app/shared/models/notification';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  client_name: string = environment.client_name;
  app_name_long: string = environment.app_name_long;
  toggle_feature_notification = environment.toggle_feature_notification;
  toggle_feature_notification_preference = environment.toggle_feature_notification_preference;
  toogle_feature_modal_news = environment.toogle_feature_modal_news;
  fiche_memo_link = environment.fiche_memo_link;
  reporting_link = environment.reporting_link;
  suggestion_url = environment.suggestion_url;
  bug_url = environment.bug_url;
  RGPD_KEY = 'rgpd-seen';

  public user: User = null;
  popup: string;
  hypervision_apps: any[] = Apps;
  user_apps: any[];
  mode: string;

  notifications: AppNotification[] = [];

  isParam = true;

  isSmallScreen: boolean;
  panelClass: string = '';

  urlHubgradePass: string = environment.HUBGRADE_PASS_URL;
  externalUser: boolean;

  rolesTostring() {
    const rolesOrder = ['Observateur', 'Acteur', 'Paramétreur'];
    return this.user.roles
      .map((r) => r.label)
      .sort((a, b) => {
        return rolesOrder.indexOf(a) > rolesOrder.indexOf(b) ? 1 : -1;
      })
      .join(', ');
  }

  @ViewChild('lineoHeader') lineoHeader: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private userPermission: UserPermission,
    private breakPoint: BreakpointObserver,
    private notificationService: NotificationService,
  ) {
    const maxWidth = '(max-width: 991px)';
    const minWidth = '(min-width: 992px)';
    this.isSmallScreen = this.breakPoint.isMatched(maxWidth);
    this.breakPoint.observe([maxWidth, minWidth])
      .subscribe((result) => {
        this.isSmallScreen = result.breakpoints[maxWidth];
      });
    this.panelClass = this.isSmallScreen ? 'panel-mobile' : 'panel';
    this.authService.getUser().then((_user) => {
      this.user = _user as User;
      this.externalUser = (!this.user.email.includes("@veolia.com") && !this.user.email.includes("@partenaire.veolia.fr")) ? true : false;
      // returns apps from hypervision_apps where
      // the id is included in user.modules
      // (Which means the users has access to the given apps)
      this.user_apps = this.hypervision_apps.filter((app) =>
        this.user.modules.some((module) => module.id === app.id),
      );
      this.hypervision_apps.sort((a, b) => (a.label > b.label ? 1 : -1));

      // Show the RGPD modal if the user has not yet seen it or a new MEP or
      // if more than three months have passed since the last view of the rgpd modal.
      if (!this.toogle_feature_modal_news) {
        if (!localStorage.getItem(this.RGPD_KEY) ||
          moment(localStorage.getItem(this.RGPD_KEY)).diff(moment(), 'days') < -90) {
          if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'none';
          const template = {
            disableClose: false,
            closeOnNavigation: false,
            width: '750px',
            minHeight: '520px',
            ariaLabel: 'RGPD',
            ariaModal:true,
          }
          const rgpdDialog = this.dialog.open(RgpdModalComponent, template);
          rgpdDialog.afterClosed().subscribe(() => {
            localStorage.setItem(this.RGPD_KEY, moment().toString());
            if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'block';
          });
        }
      } else {
        if (!localStorage.getItem(CACHE_KEY) || !localStorage.getItem(this.RGPD_KEY) ||
          moment(localStorage.getItem(this.RGPD_KEY)).diff(moment(), 'days') < -90) {
          if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'none';
          const template = {
            disableClose: false,
            closeOnNavigation: false,
            width: '750px',
            minHeight: '520px',
            ariaLabel:"RGPD",
            ariaModal:true,
          }
          const rgpdDialog = this.dialog.open(RgpdModalComponent, template);
          rgpdDialog.afterClosed().subscribe(() => {
            localStorage.setItem(this.RGPD_KEY, moment().toString());
            if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'block';
          });
        }
      }

      // Show MEP about modal if user has not seen it yet
      if (this.toogle_feature_modal_news && !localStorage.getItem(CACHE_KEY) && this.client_name != 'ileo') {
        if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'none';
        const template = {
          disableClose: false,
          closeOnNavigation: false,
          width: '800px',
          panelClass: this.panelClass,
          ariaLabel:"A propos",
          ariaModal:true,
        }
        this.dialog.open(AboutModalComponent, template)
          .afterClosed().subscribe(() => {
            localStorage.setItem(CACHE_KEY, String(true));
          });
      }
    });
  }

  isDirectUrl(){
    if (this.router.url.split('?')[0] == "/demands/new") {
      return true;
    }
    return false
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.mode = getUserMode();
      }
    });

    this.mode = getUserMode();
    if (!this.mode) {
      // Set mode demandeur by default
      this.userPermission.hasExploitantRight().then((val) => {
        if (val) {
          setUserMode(UserType.EXPLOITANT);
          this.mode = UserType.EXPLOITANT;
        } else {
          setUserMode(UserType.DEMANDEUR);
          this.mode = UserType.DEMANDEUR;
        }
        // Redirect to home (Demandeur main route)
        if(!this.isDirectUrl()){
          this.router.navigate([getUserHome()]);
        }
      });
    }

    if (this.toggle_feature_notification) this.getNotifications();

    this.authService.getUser().then((_user) => {
      this.isParam = this.user.roles.find((role) => role.id === "PARAMETREUR");
    });
  }

  newPopup(element: string) {
    this.popup = element;
  }

  popupToggle(element: string) {
    if (!this.popup || this.popup !== element) {
      this.newPopup(element);
    } else {
      this.closePopup();
    }
  }

  isPopup(element: string) {
    return this.popup === element;
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.popup) {
      const targetElement = event.target as HTMLElement;
      if (!targetElement.closest('[click-bypass]')) {
        this.closePopup();
      }
    }
  }

  @HostListener('document:scroll', [])
  public onDocumentScroll(): void {
    this.closePopup();
  }

  @HostListener('document:keydown', ['$event'])
  private onEscape(event): void {
    if(event.code == 'Escape' && this.popup != "") {
      const tempPopup = this.popup;
      this.closePopup();
      setTimeout(() => {document.getElementById(tempPopup).focus();}, 0)
    }
  }

  modeChanged(e) {
    setUserMode(e.value);
    this.router.navigate([getUserHome()]);
    sessionStorage.setItem('demandsTablePageIndex', '0');
    this.closePopup();
  }

  isCollectivite() {
    return this.user.profile_type === 'Collectivité';
  }

  logout() {
    this.authService.logout()
  }

  rgpd() {
    if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'none';
    const template = {
      disableClose: false,
      closeOnNavigation: false,
      width: '800px',
      panelClass: this.panelClass,
      ariaLabel:"RGPD",
      ariaModal:true,
    }
    this.dialog.open(RgpdModalComponent, template).afterClosed().subscribe(() => {
      if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'block';
    });
    this.closePopup();
  }

  public showAboutModal() {
    if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'none';
    const template = {
      disableClose: false,
      closeOnNavigation: false,
      width: '800px',
      panelClass: this.panelClass,
      ariaLabel:"A propos",
      ariaModal:true,
    }
    this.dialog.open(AboutModalComponent, template).afterClosed().subscribe(() => {
      if (this.isSmallScreen) this.lineoHeader.nativeElement.style.display = 'block';
    });
    this.closePopup();
  }

  closePopup() {
    if (this.popup !== '') {
      this.popup = '';
    }
  }

  delayBetweenNotificationRefreshInSecond = 5 * 60;
  numberOfUnreadNotification = 0;

  getNotifications() {
    this.notificationService.getNotifications().then((notifications: AppNotification[]) => {
      this.notifications = notifications.length > 100 ? notifications.slice(0, 100) : notifications;
      this.numberOfUnreadNotification = this.notifications.filter((notification) => notification.read === false).length;

      setTimeout(() => {
        this.getNotifications();
      }, this.delayBetweenNotificationRefreshInSecond * 1000);
    });
  }

  seeAllNotification() {
    this.router.navigate(['/notification']);
    this.closePopup();
  }

  readNotification() {
    let ids = this.notifications.filter((notification) => notification.read === false).map((notification) => notification.id);
    if (ids.length > 0) {
      this.notificationService.readNotifications(ids).then(() => {
        this.notifications.map((notification) => notification.read = true);
        this.numberOfUnreadNotification = 0;
      });
    }
  }

  openDemand(notification) {
    if (notification.demand) {
      // FIX ME : Faire quelque chose de plus générique
      if(notification.title.includes("Commentaire interne")) {
        const demandURI = {
          demandeur: '/demands/',
          exploitant: '/exploitant/demands/',
        }[getUserMode()];
        this.router.navigate([`${demandURI}${notification.demand}`], {fragment:"historique"});
        this.closePopup();
      } else {
        const demandURI = {
          demandeur: '/demands/',
          exploitant: '/exploitant/demands/',
        }[getUserMode()];
        this.router.navigate([`${demandURI}${notification.demand}`]);
        this.closePopup();
      }
      // Pour ouverture dans une nouvelle fenetre
      /*let url = this.router.createUrlTree([`${demandURI}${notification.demand}`]);
      window.open('#' + url.toString(), '_detailDemand');*/
    }
  }

  checkKey(event) {
    if(event.code == "Space" || event.code == "Enter") {
      (document.activeElement as HTMLElement).click()
    }
  }
}
