import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { BtnAbandonDemandComponent } from './btn-transition/btn-abandon-demand/btn-abandon-demand.component';
import { BtnTransitionBaseComponent } from './btn-transition/btn-transition.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationInputModalComponent } from './modals/confirmation-input-modal/confirmation-input-modal.component';
import { BtnEnvoyerDemandComponent } from './btn-transition/btn-envoyer-demand/btn-envoyer-demand.component';
import { BtnAccepterClotureComponent } from './btn-transition/btn-accepter-cloture/btn-accepter-cloture.component';
import { BtnRejeterClotureComponent } from './btn-transition/btn-rejeter-cloture/btn-rejeter-cloture.component';
import { BtnAccepterAbandonComponent } from './btn-transition/btn-accepter-abandon/btn-accepter-abandon.component';
import { BtnRejeterAbandonComponent } from './btn-transition/btn-rejeter-abandon/btn-rejeter-abandon.component';
import { BtnReprendreDemandComponent } from './btn-transition/btn-reprendre-demand/btn-reprendre-demand.component';
import { BtnEnvoyerCommentaireComponent } from './btn-transition/btn-envoyer-commentaire/btn-envoyer-commentaire.component'
import {
  BtnDemanderPointAvancementComponent,
} from './btn-transition/btn-demander-point-avancement/btn-demander-point-avancement.component';
import {
  BtnEnvoyerPointAvancementComponent,
} from './btn-transition/btn-envoyer-point-avancement/btn-envoyer-point-avancement.component';
import { UploadComponent } from './upload/uploader/upload.component';
import {
  BtnDemanderPrecisionsComponent,
} from './btn-transition/btn-demander-precisions/btn-demander-precisions.component';
import {
  BtnApporterPrecisionsComponent,
} from './btn-transition/btn-apporter-precisions/btn-apporter-precisions.component';
import { BtnDemanderClotureComponent } from './btn-transition/btn-demander-cloture/btn-demander-cloture.component';
import { BtnAffecterDemandComponent } from './btn-transition/btn-affecter-demand/btn-affecter-demand.component';
import { AffecterModalComponent } from './modals/affecter-modal/affecter-modal.component';
import { BtnSuspendreDemandComponent } from '@app/components/btn-transition/btn-suspendre-demand/btn-suspendre-demand.component';
import { BtnOpenMoveoComponent } from '@app/components/btn-transition/btn-open-moveo/btn-open-moveo.component';
import { BtnDemanderAbandonComponent } from '@app/components/btn-transition/btn-demander-abandon/btn-demander-abandon.component';
import { BtnTraiterDemandComponent } from '@app/components/btn-transition/btn-traiter-demand/btn-traiter-demand.component';
import { UserBadgeComponent } from '@app/shared/components/user-badge/user-badge.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { RgpdModalComponent } from './modals/rgpd-modal/rgpd-modal.component';
import { AttachmentsComponent } from '@app/components/upload/attachments/attachments.component';
import { DemandPdfBtnComponent } from '@app/shared/components/demand-pdf-btn/demand-pdf-btn.component';
import { DemandStatusInfoComponent } from '../shared/components/demand-status-info/demand-status-info.component';
import { FilterComponent } from '@app/shared/components/filter/filter.component';
import { GoogleMapsComponent } from '@app/dashboard/home/components/google-maps/google-maps.component';
import { DemandUploadBtnComponent } from '@app/shared/components/demand-upload-btn/demand-upload-btn.component';
import { SkiplinksComponent } from '@app/shared/components/skiplinks/skiplinks.component';
import { ReviewFormComponent } from '@app/shared/components/reviews/review-form/review-form.component';
import { ReviewListComponent } from '@app/shared/components/reviews/review-list/review-list.component';
import { RatingComponent } from '@app/shared/components/rating/rating.component';
import { AjoutUrlModalComponent } from './modals/ajout-url-modal/ajout-url-modal.component';
import { SuspensionModalComponent } from './modals/suspension-modal/suspension-modal.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
} from '@lukana/ng-pick-datetime';
import { DefaultIntl } from 'src/app/shared/helpers/pick-datetime-intl';
import { CustomDateAdapter } from '@app/shared/helpers/custom-date-adapter';
import { CreateNotificationModalComponent } from './modals/create-notification-modal/create-notification-modal.component';
import { GenericTableComponent } from './generic-table/generic-table.component';
import { PropagationPreferencesModalComponent } from './modals/propagation-preferences-modal/propagation-preferences-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { IndicatorComponent } from '@app/dashboard/indicators-dashboard/indicator/indicator.component';
import { ChartModule } from 'angular-highcharts';
import { DetailNotificationModalComponent } from './modals/detail-notification-modal/detail-notification-modal.component';
import { ResetPasswordModalComponent } from './modals/reset-password-modal/reset-password-modal.component';
import { DetailMapModalComponent } from './modals/detail-map-modal/detail-map-modal.component';
import { AboutModalComponent } from './modals/about-modal/about-modal.component';
import { MinutesDurationPipe } from '@app/pipes/minutes-duration.pipe';
import { DecimalFormatPipe } from '@app/pipes/decimal.pipe';
import { BtnAjouterCommentaireInterneComponent } from './btn-transition/btn-ajouter-commentaire-interne/btn-ajouter-commentaire-interne.component';
import { DateAdapter } from '@angular/material/core';
import { GoogleMapsModule } from '@angular/google-maps';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelectModule,
    ChartModule,
    GoogleMapsModule
  ],
  declarations: [
    BtnTransitionBaseComponent,
    BtnAbandonDemandComponent,
    BtnDemanderAbandonComponent,
    BtnEnvoyerDemandComponent,
    BtnAccepterClotureComponent,
    BtnRejeterClotureComponent,
    BtnDemanderClotureComponent,
    BtnEnvoyerPointAvancementComponent,
    BtnDemanderPointAvancementComponent,
    BtnEnvoyerCommentaireComponent,
    BtnDemanderPrecisionsComponent,
    BtnApporterPrecisionsComponent,
    BtnAffecterDemandComponent,
    BtnRejeterAbandonComponent,
    BtnAccepterAbandonComponent,
    BtnAjouterCommentaireInterneComponent,
    AffecterModalComponent,
    BtnReprendreDemandComponent,
    BtnSuspendreDemandComponent,
    BtnOpenMoveoComponent,
    BtnTraiterDemandComponent,
    ConfirmationModalComponent,
    ConfirmationInputModalComponent,
    UserBadgeComponent,
    UploadComponent,
    AttachmentsComponent,
    RgpdModalComponent,
    DemandPdfBtnComponent,
    DemandStatusInfoComponent,
    FilterComponent,
    DemandUploadBtnComponent,
    AjoutUrlModalComponent,
    SuspensionModalComponent,
    CreateNotificationModalComponent,
    GenericTableComponent,
    PropagationPreferencesModalComponent,
    IndicatorComponent,
    DetailNotificationModalComponent,
    ResetPasswordModalComponent,
    DetailMapModalComponent,
    AboutModalComponent,
    MinutesDurationPipe,
    DecimalFormatPipe,
    SkiplinksComponent,
    ReviewFormComponent,
    ReviewListComponent,
    RatingComponent,
  ],
  exports: [
    BtnTransitionBaseComponent,
    BtnAbandonDemandComponent,
    BtnDemanderAbandonComponent,
    BtnEnvoyerDemandComponent,
    BtnAccepterClotureComponent,
    BtnRejeterClotureComponent,
    BtnDemanderClotureComponent,
    BtnEnvoyerPointAvancementComponent,
    BtnDemanderPointAvancementComponent,
    BtnEnvoyerCommentaireComponent,
    BtnDemanderPrecisionsComponent,
    BtnApporterPrecisionsComponent,
    BtnAffecterDemandComponent,
    BtnRejeterAbandonComponent,
    BtnAccepterAbandonComponent,
    BtnAjouterCommentaireInterneComponent,
    AffecterModalComponent,
    BtnReprendreDemandComponent,
    BtnSuspendreDemandComponent,
    BtnOpenMoveoComponent,
    BtnTraiterDemandComponent,
    ConfirmationModalComponent,
    ConfirmationInputModalComponent,
    UserBadgeComponent,
    UploadComponent,
    AttachmentsComponent,
    RgpdModalComponent,
    DemandPdfBtnComponent,
    DemandStatusInfoComponent,
    FilterComponent,
    DemandUploadBtnComponent,
    GenericTableComponent,
    IndicatorComponent,
    MinutesDurationPipe,
    DecimalFormatPipe,
    SkiplinksComponent,
    ReviewFormComponent,
    ReviewListComponent,
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: LOCALE_ID, useValue: "fr" }
  ],
})

export class ComponentsModule {}
