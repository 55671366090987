<div *ngIf="client_name === 'sabom'">
  <h2 mat-dialog-title>Règlement Général sur la Protection des Données (RGPD)</h2>
  <mat-dialog-content class="mat-typography text-justify">
    <p>
      En tant qu’utilisateur de l’outil de Gestion des demandes collectivités, des données personnelles vous concernant
      sont nécessaires pour gérer votre accès.<br>
      Il s’agit de:<br>
      • Votre nom et votre prénom <br>
      • Votre identifiant (constitué de votre adresse mail)<br>
      • Votre numéro de téléphone<br>
    </p>
    <p>
      Elles sont accessibles par les équipes informatiques en charge de l'administration de l'application,
      et conservées tant que l'accès à l'application est nécessaire. <br>
      Conformément à la réglementation applicable en matière de données à caractère personnel,
      vous pouvez exercer vos droits de rectification, d'effacement, de limitation, de portabilité auprès de la personne
      en charge de
      la protection des données personnelles (DPO) par messagerie électronique à l'adresse : dpo&#64;sabom.fr.
    </p>
    <mat-checkbox (change)="checkedrgpd=!checkedrgpd">Par cette coche, je consens à ce que mes données personnelles
      soient utilisées dans le cadre de ce service</mat-checkbox>
    <p>
      Par ailleurs, en accédant à l’outil de Gestion des demandes collectivités, vous pouvez accéder à des données à
      caractère personnel,
      c'est-à-dire à des informations relatives à des personnes physiques susceptibles d'être identifiées, directement
      ou indirectement.
      La diffusion de ces informations présente un risque pour la vie privée des personnes concernées, et pour vos
      organismes.
    </p>
    <p>
      Par conséquent, vous vous engagez à prendre toutes précautions conformes aux usages et à l’état de l’art
      dans le cadre de vos attributions afin de protéger la confidentialité des informations auxquelles vous avez accès,
      et en particulier d’empêcher qu’elles ne soient communiquées à des personnes non expressément autorisées à les
      recevoir.
    </p>
    <p>
      De plus, dans l’outil de Gestion des demandes collectivités, vous êtes amenés à saisir des informations dans des
      champs de saisie
      libre; ayez à l'esprit que la personne concernée peut à tout moment exercer son droit d'accès et lire ces
      commentaires.
      Par conséquent, vous vous engagez à prendre toutes les précautions afin que vos
      commentaires soient toujours objectifs, adéquats, pertinents et non excessifs au regard de la finalité du
      traitement.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [disabled]="!checkedrgpd" mat-raised-button class="uppercased mat-primary" mat-dialog-close>
      J'ai compris
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="client_name === 'ileo'">
  <h2 mat-dialog-title>Règlement Général sur la Protection des Données (RGPD)</h2>
  <mat-dialog-content class="mat-typography text-justify">
    <p>
      Lors de l'utilisation de Gestion des demandes de la collectivité, des informations techniques sont recueillies
      concernant votre navigation et font l’objet d’un traitement par les équipes
      informatiques de ILEO afin d'améliorer votre expérience utilisateur et
      assurer la sécurité du système d'information. Vos statistiques de connexion sont
      enregistrées et exploitées de manière anonyme pour permettre le suivi
      du déploiement de nos modules. Vous pouvez exercer vos droits
      concernant vos données personnelles auprès de votre DRH (si vous êtes
      employés par ILEO) ou de notre DPO (Délégué à la Protection des Données
      <a href="mailto:veolia-eau-france.dpo@veolia.com" target="_blank">veolia-eau-france.dpo&#64;veolia.com</a>)
      sinon.
    </p>
    <p>
      Par ailleurs, en accédant à Gestion des demandes de la collectivité, vous pouvez accéder à des données à
      caractère personnel, c'est-à-dire à des informations relatives à des
      personnes physiques susceptibles d'être identifiées, directement ou
      indirectement. La diffusion de ces informations présente un risque pour la
      vie privée des personnes concernées, et pour vos organismes.
    </p>
    <p>
      Par conséquent, vous vous engagez à prendre toutes précautions conformes aux
      usages et à l’état de l’art dans le cadre de vos attributions afin de
      protéger la confidentialité des informations auxquelles vous avez accès, et
      en particulier d’empêcher qu’elles ne soient communiquées à des personnes
      non expressément autorisées à les recevoir.
    </p>
    <p>
      De plus, dans Gestion des demandes de la collectivité, vous êtes amenés à saisir des informations dans des
      champs de saisie libre; ayez à l'esprit que la personne concernée peut à
      tout moment exercer son droit d'accès et lire ces commentaires. Par
      conséquent, vous vous engagez à prendre toutes les précautions afin que vos
      commentaires soient toujours objectifs, adéquats, pertinents et non
      excessifs au regard de la finalité du traitement.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="uppercased mat-primary" mat-dialog-close cdkFocusInitial>
      J'ai compris
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="client_name === 'national'">
  <h2 mat-dialog-title>Règlement Général sur la Protection des Données (RGPD)</h2>
  <mat-dialog-content class="mat-typography text-justify">
    <p>
      Lors de l'utilisation de Lineo, des informations techniques sont recueillies
      concernant votre navigation et font l’objet d’un traitement par les équipes
      informatiques de Veolia afin d'améliorer votre expérience utilisateur et
      assurer la sécurité du système d'information. Vos statistiques de connexion sont
      enregistrées et exploitées de manière anonyme pour permettre le suivi
      du déploiement de nos modules. Vous pouvez exercer vos droits
      concernant vos données personnelles auprès de votre DRH (si vous êtes
      employés par Veolia) ou de notre DPO (Délégué à la Protection des Données
      <a href="mailto:veolia-eau-france.dpo@veolia.com" target="_blank">veolia-eau-france.dpo&#64;veolia.com</a>)
      sinon.
    </p>
    <p>
      Par ailleurs, en accédant à Lineo, vous pouvez accéder à des données à
      caractère personnel, c'est-à-dire à des informations relatives à des
      personnes physiques susceptibles d'être identifiées, directement ou
      indirectement. La diffusion de ces informations présente un risque pour la
      vie privée des personnes concernées, et pour vos organismes.
    </p>
    <p>
      Par conséquent, vous vous engagez à prendre toutes précautions conformes aux
      usages et à l’état de l’art dans le cadre de vos attributions afin de
      protéger la confidentialité des informations auxquelles vous avez accès, et
      en particulier d’empêcher qu’elles ne soient communiquées à des personnes
      non expressément autorisées à les recevoir.
    </p>
    <p>
      De plus, dans Lineo, vous êtes amenés à saisir des informations dans des
      champs de saisie libre; ayez à l'esprit que la personne concernée peut à
      tout moment exercer son droit d'accès et lire ces commentaires. Par
      conséquent, vous vous engagez à prendre toutes les précautions afin que vos
      commentaires soient toujours objectifs, adéquats, pertinents et non
      excessifs au regard de la finalité du traitement.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="uppercased mat-primary" mat-dialog-close cdkFocusInitial>
      J'ai compris
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="client_name === 'setom'">
  <h2 mat-dialog-title>Règlement Général sur la Protection des Données (RGPD)</h2>
  <mat-dialog-content class="mat-typography text-justify">
    <p>
      Lors de l'utilisation de Plateforme des demandes eau potable, des informations techniques sont recueillies
      concernant votre navigation et font l’objet d’un traitement par les équipes
      informatiques de Veolia afin d'améliorer votre expérience utilisateur et
      assurer la sécurité du système d'information. Vous pouvez exercer vos droits
      concernant vos données personnelles auprès de votre DRH (si vous êtes
      employés par Veolia) ou de notre DPO (Délégué à la Protection des Données
      <a href="mailto:veolia-eau-france.dpo@veolia.com" target="_blank">veolia-eau-france.dpo&#64;veolia.com</a>)
      sinon.
    </p>
    <p>
      Par ailleurs, en accédant à Plateforme des demandes eau potable, vous pouvez accéder à des données à
      caractère personnel, c'est-à-dire à des informations relatives à des
      personnes physiques susceptibles d'être identifiées, directement ou
      indirectement. La diffusion de ces informations présente un risque pour la
      vie privée des personnes concernées, et pour vos organismes.
    </p>
    <p>
      Par conséquent, vous vous engagez à prendre toutes précautions conformes aux
      usages et à l’état de l’art dans le cadre de vos attributions afin de
      protéger la confidentialité des informations auxquelles vous avez accès, et
      en particulier d’empêcher qu’elles ne soient communiquées à des personnes
      non expressément autorisées à les recevoir.
    </p>
    <p>
      De plus, dans Plateforme des demandes eau potable, vous êtes amenés à saisir des informations dans des
      champs de saisie libre; ayez à l'esprit que la personne concernée peut à
      tout moment exercer son droit d'accès et lire ces commentaires. Par
      conséquent, vous vous engagez à prendre toutes les précautions afin que vos
      commentaires soient toujours objectifs, adéquats, pertinents et non
      excessifs au regard de la finalité du traitement.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button class="uppercased mat-primary" mat-dialog-close cdkFocusInitial>
      J'ai compris
    </button>
  </mat-dialog-actions>
</div>
