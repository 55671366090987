
<div mat-dialog-title class="title">{{ title }}</div>
<mat-dialog-content [innerHtml]="description">
  </mat-dialog-content>
<mat-dialog-actions >
        <button class="cancel-btn uppercased mat-primary" mat-button mat-dialog-close (click)="refuse()"> {{ labelRefuse  }}</button>
        <button *ngIf="title !== 'Remise à zéro'" class="uppercased mat-primary" mat-button (click)="confirm()">{{ labelConfirm }}</button>
        <button *ngIf="title === 'Remise à zéro'" class="uppercased mat-warn" mat-button
          (click)="confirm()">{{ labelConfirm }}</button>
</mat-dialog-actions>
